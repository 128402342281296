import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { pending, tooltTitle } from "../../constant/constant";
import { createColumnHelper } from "@tanstack/react-table";
import { black } from "../../constant/color";
import { getStatusStyle } from "../../assets/styles/chipStyles";
import {
  AttachmentFileIcon,
  DeleteIcon,
  EditIcon,
  VisibilityIcon,
} from "../../components/svg";
import { checkBoxStyle } from "../../assets/styles/checkboxStyle";
import { capitalizeAndFormat, dateFormat } from "../../utils/helper";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { useSelector } from "react-redux";
import { matchStatusStyleTable, modules, permissionsKey } from "../data";
import { checkPermissions } from "../../utils/checkPermission";
import HistoryIcon from "@mui/icons-material/History";
import { Link } from "react-router-dom";
import axiosConfig from "../../config/axiosConfig";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import { getCurrency } from "../../utils/helper";
import Expandable from "../../components/expandable/Expandable";

const withSorting = (columns) => {
  return columns.map((column) => ({
    ...column,
    enableSorting: !["actions", "status"].includes(column.accessorKey), // Disable sorting for action columns
    sortingFn: "alphanumeric",
  }));
};

const columnHelper = createColumnHelper();

const RenderUsersActions = ({ row, table }) => {
  const { user } = useSelector((state) => state.auth);

  const userPermission = user?.permissions[modules.users];
  const { u, d } = permissionsKey;

  return (
    <>
      {row?.original?.status === pending && (
        <Tooltip
          title={tooltTitle.resend_activ_link}
          {...tooltiPlacemetpProps}
          disableInteractive
        >
          <IconButton
            sx={{ py: 0, px: 0.5 }}
            onClick={() => table.options.meta.sendemail(row?.original)}
          >
            <ForwardToInboxIcon
              sx={{ color: black, fontSize: 20 }}
              height="15"
              width="15"
            />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip
        title={tooltTitle.view}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.view(row?.original)}
        >
          <VisibilityIcon fillColor={black} height="18" width="18" />
        </IconButton>
      </Tooltip>
      {checkPermissions(userPermission, [u]) && (
        <Tooltip
          title={tooltTitle.edit}
          {...tooltiPlacemetpProps}
          disableInteractive
        >
          <IconButton
            sx={{ py: 0, px: 0.5 }}
            onClick={() => table.options.meta.edit(row?.original)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {checkPermissions(userPermission, [d]) && (
        <>
          <Tooltip
            title={tooltTitle.delete}
            {...tooltiPlacemetpProps}
            disableInteractive
          >
            <IconButton
              sx={{ py: 0, px: 0.5 }}
              onClick={() => table.options.meta.delete(row?.original)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip
        title={tooltTitle.history}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.history(row?.original)}
        >
          <HistoryIcon sx={{ color: "var(--primary-color)", fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

const RenderRolesActions = ({ row, table }) => {
  const { user } = useSelector((state) => state.auth);
  const rolesPermission = user?.permissions[modules.roles];
  const { u, d } = permissionsKey;
  return (
    <>
      <Tooltip
        title={tooltTitle.view}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.view(row?.original)}
        >
          <VisibilityIcon fillColor={black} height="18" width="18" />
        </IconButton>
      </Tooltip>
      {checkPermissions(rolesPermission, [u]) && (
        <Tooltip
          title={tooltTitle.edit}
          {...tooltiPlacemetpProps}
          disableInteractive
        >
          <IconButton
            sx={{ py: 0, px: 0.5 }}
            onClick={() => table.options.meta.edit(row?.original)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {checkPermissions(rolesPermission, [d]) && (
        <>
          <Tooltip
            title={tooltTitle.delete}
            {...tooltiPlacemetpProps}
            disableInteractive
          >
            <IconButton
              sx={{ py: 0, px: 0.5 }}
              onClick={() => table.options.meta.delete(row?.original)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip
        title={tooltTitle.history}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.history(row?.original)}
        >
          <HistoryIcon sx={{ color: "var(--primary-color)", fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

const RenderGraphActions = ({ row, table }) => {
  const { user } = useSelector((state) => state.auth);
  const graphPermission = user?.permissions[modules.graph];
  const { u, d } = permissionsKey;

  return (
    <>
      <Tooltip
        title={`${tooltTitle.preview} Graph`}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => {
            table.options.meta.previewGraph(row?.original);
          }}
        >
          <EqualizerOutlinedIcon
            sx={{ color: black, fontSize: 20 }}
            height="15"
            width="15"
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={tooltTitle.view}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.view(row?.original)}
        >
          <VisibilityIcon fillColor={black} height="18" width="18" />
        </IconButton>
      </Tooltip>
      {checkPermissions(graphPermission, [u]) && (
        <Tooltip
          title={tooltTitle.edit}
          {...tooltiPlacemetpProps}
          disableInteractive
        >
          <IconButton
            sx={{ py: 0, px: 0.5 }}
            onClick={() => table.options.meta.edit(row?.original)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {checkPermissions(graphPermission, [d]) && (
        <>
          <Tooltip
            title={tooltTitle.delete}
            {...tooltiPlacemetpProps}
            disableInteractive
          >
            <IconButton
              sx={{ py: 0, px: 0.5 }}
              onClick={() => table.options.meta.delete(row?.original)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip
        title={tooltTitle.history}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.history(row?.original)}
        >
          <HistoryIcon sx={{ color: "var(--primary-color)", fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

const RenderAlertActions = ({ row, table }) => {
  const { user } = useSelector((state) => state.auth);

  const alertPermission = user?.permissions[modules.alerts];
  const { u, d } = permissionsKey;
  return (
    <>
      <Tooltip
        title={tooltTitle.sent_history}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.sentHistory(row?.original)}
        >
          <ForwardToInboxIcon
            sx={{ color: "var(--primary-color)", fontSize: 20 }}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={tooltTitle.view}
        {...tooltiPlacemetpProps}
        disableInteractive
      ></Tooltip>

      <Tooltip
        title={tooltTitle.view}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.view(row?.original)}
        >
          <VisibilityIcon fillColor={black} height="18" width="18" />
        </IconButton>
      </Tooltip>
      {checkPermissions(alertPermission, [u]) && (
        <Tooltip
          title={tooltTitle.edit}
          {...tooltiPlacemetpProps}
          disableInteractive
        >
          <IconButton
            sx={{ py: 0, px: 0.5 }}
            onClick={() => table.options.meta.edit(row?.original)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {checkPermissions(alertPermission, [d]) && (
        <>
          <Tooltip
            title={tooltTitle.delete}
            {...tooltiPlacemetpProps}
            disableInteractive
          >
            <IconButton
              sx={{ py: 0, px: 0.5 }}
              onClick={() => table.options.meta.delete(row?.original)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip
        title={tooltTitle.history}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.history(row?.original)}
        >
          <HistoryIcon sx={{ color: "var(--primary-color)", fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

const RenderTemplateActions = ({ row, table }) => {
  // const { user } = useSelector((state) => state.auth);

  // const rolesPermission = user?.permissions[modules.roles];
  // const { u, d } = permissionsKey;

  return (
    <>
      <Tooltip
        title={`${tooltTitle.download} Template`}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.export(row?.original)}
        >
          <AttachmentFileIcon />
        </IconButton>
      </Tooltip>

      <Tooltip
        title={tooltTitle.edit}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.edit(row?.original)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <>
        <Tooltip
          title={tooltTitle.delete}
          {...tooltiPlacemetpProps}
          disableInteractive
        >
          <IconButton
            sx={{ py: 0, px: 0.5 }}
            onClick={() => table.options.meta.delete(row?.original)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={tooltTitle.history}
          {...tooltiPlacemetpProps}
          disableInteractive
        >
          <IconButton
            sx={{ py: 0, px: 0.5 }}
            onClick={() => table.options.meta.history(row?.original)}
          >
            <HistoryIcon sx={{ color: "var(--primary-color)", fontSize: 20 }} />
          </IconButton>
        </Tooltip>
      </>
    </>
  );
};
// Factory Parts
const AircraftMasterActions = ({ row, table }) => {
  return (
    <>
      <Tooltip
        title={tooltTitle.delete}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.delete(row?.original)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

const RenderIlsActions = ({ row, table }) => {
  const { user } = useSelector((state) => state.auth);
  const ilsPemission = user?.permissions[modules.ils];
  const { d } = permissionsKey;

  return (
    <>
      {checkPermissions(ilsPemission, [d]) && (
        <>
          <Tooltip
            title={tooltTitle.delete}
            {...tooltiPlacemetpProps}
            disableInteractive
          >
            <IconButton
              sx={{ py: 0, px: 0.5 }}
              onClick={() => table.options.meta.delete(row?.original)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
};

export const tooltiPlacemetpProps = {
  placement: "top",
};

// users
export const columnData = withSorting([
  columnHelper.accessor("name", {
    id: "name",
    header: "Name",
    isVisible: true,
    // cell: (info) => info.getValue(),
    // footer: (info) => info.column.id,
  }),
  columnHelper.accessor("department", {
    id: "department",
    header: "Department",
    isVisible: true,
    cell: ({ row }) => {
      const department = row?.original?.department;
      return <>{department || "-"}</>;
    },
  }),
  columnHelper.accessor("designation", {
    id: "designation",
    header: "Designation",
    isVisible: true,
    cell: ({ row }) => {
      const designation = row?.original?.designation;
      return <>{designation || "-"}</>;
    },
  }),
  columnHelper.accessor("email", {
    id: "email",
    header: "Email",
    isVisible: true,
  }),
  columnHelper.accessor("mobile_number", {
    id: "mobile_number",
    header: "Mobile",
    isVisible: true,
    cell: ({ row }) => {
      const mobile_number = row?.original?.mobile_number;
      return <>{mobile_number || "-"}</>;
    },
  }),
  columnHelper.accessor("status", {
    id: "status",
    header: "Status",
    isVisible: true,
    cell: ({ row }) => {
      const status = row?.original?.status;
      return (
        <Chip
          label={status}
          sx={getStatusStyle(matchStatusStyleTable[status])}
        />
      );
    },
  }),
  columnHelper.accessor("roles", {
    id: "roles",
    header: "Access",
    isVisible: true,
  }),
  columnHelper.accessor("last_login", {
    id: "last_login",
    header: "Last Activity",
    isVisible: true,
    cell: ({ row }) => {
      const last_login = row?.original?.last_login;
      return <>{dateFormat(last_login)}</>;
    },
  }),
  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    isVisible: true,
    cell: ({ row, table }) => <RenderUsersActions row={row} table={table} />,
  }),
]);

// roles
export const rolesColumnData = withSorting([
  columnHelper.accessor("role_name", {
    id: "role_name",
    header: "Name",
    // cell: (info) => info.getValue(),
    // footer: (info) => info.column.id,
  }),
  columnHelper.accessor("profile_type", {
    id: "profile_type",
    header: "Profile Type",
  }),

  columnHelper.accessor("user_count", {
    id: "user_count",
    header: "Users",
  }),
  columnHelper.accessor("created_by", {
    id: "created_by",
    header: "Created By",
  }),
  columnHelper.accessor("updated_by", {
    id: "updated_by",
    header: "Updated By",
  }),
  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    cell: ({ row, table }) => <RenderRolesActions row={row} table={table} />,
  }),
]);

//GraphColumn with Sorting

export const graphColumnData = [
  columnHelper.accessor("title", {
    id: "title",
    header: "Title",
  }),
  columnHelper.accessor("order", {
    id: "order",
    header: "Order",
  }),
  columnHelper.accessor("type", {
    id: "type",
    header: "Type",
  }),
  columnHelper.accessor("status", {
    id: "status",
    header: "Status",
    cell: ({ row }) => {
      const status = row?.original?.status;
      return (
        <Chip
          label={status === true ? "Active" : "Inactive"}
          sx={getStatusStyle(
            matchStatusStyleTable[status === true ? "Active" : "Inactive"]
          )}
        />
      );
    },
  }),
  columnHelper.accessor("roles", {
    id: "roles",
    header: "Roles",
    enableSorting: false,
    cell: ({ row }) => {
      const roles = row?.original?.roles;
      if (!roles?.length) return "-";

      return (
        <div style={{ whiteSpace: "pre-line" }}>
          {roles.map(
            (role, index) =>
              role.role_name + (index < roles.length - 1 ? "\n" : "")
          )}
        </div>
      );
    },
  }),
  columnHelper.accessor("created_by_name", {
    id: "created_by_name",
    header: "Created By",
    enableSorting: false,
  }),
  columnHelper.accessor("updated_by_name", {
    id: "updated_by_name",
    header: "Updated By",
    enableSorting: false,
  }),
  columnHelper.accessor("created_at", {
    id: "created_at",
    header: "Created At",
    cell: ({ row }) => {
      const created_at = row?.original?.created_at;
      return <>{dateFormat(created_at)}</>;
    },
  }),
  columnHelper.accessor("updated_at", {
    id: "updated_at",
    header: "Updated At",
    cell: ({ row }) => {
      const updated_at = row?.original?.updated_at;
      return <>{dateFormat(updated_at)}</>;
    },
  }),
  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    cell: ({ row, table }) => <RenderGraphActions row={row} table={table} />,
  }),
];

// AlertColumn

export const alertColumnData = [
  columnHelper.accessor("alert_type", {
    id: "alert_type",
    header: "Alert Type",
  }),
  columnHelper.accessor("frequency", {
    id: "frequency",
    header: "Frequency",
  }),
  columnHelper.accessor("start_date", {
    id: "start_date",
    header: "Start Date",
  }),
  columnHelper.accessor("status", {
    id: "status",
    header: "Status",
    enableSorting: false,
  }),
  columnHelper.accessor("email_id", {
    id: "email_id",
    header: "Email",
    enableSorting: false,
  }),
  columnHelper.accessor("day_of_week", {
    id: "day_of_week",
    header: "Week Day",
    enableSorting: false,
  }),
  columnHelper.accessor("time_of_day", {
    id: "time_of_day",
    header: "Day Time",
    enableSorting: false,
    cell: ({ row }) => {
      const timeOfDay = row?.original?.time_of_day;
      if (!timeOfDay) return null;

      // Option 1: Using string manipulation
      const timeArray = timeOfDay.split(":");
      const formattedTime = `${timeArray[0]}:${timeArray[1]}`;

      // Alternative Option 2: Using moment.js
      // const formattedTime = moment(timeOfDay, 'HH:mm:ss').format('HH:mm');

      return <>{formattedTime}</>;
    },
  }),
  columnHelper.accessor("report_format", {
    id: "report_format",
    header: "Report Format",
  }),
  columnHelper.accessor("created_at", {
    id: "created_at",
    header: "Created At",
    cell: ({ row }) => {
      const created_at = row?.original?.created_at;
      return <>{dateFormat(created_at)}</>;
    },
  }),
  columnHelper.accessor("updated_at", {
    id: "updated_at",
    header: "Updated At",
    cell: ({ row }) => {
      const updated_at = row?.original?.updated_at;
      return <>{dateFormat(updated_at)}</>;
    },
  }),
  columnHelper.accessor("created_by_name", {
    id: "created_by_name",
    header: "Created By",
  }),
  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    cell: ({ row, table }) => <RenderAlertActions row={row} table={table} />,
  }),
];

// Aircraft Family Column

export const aircraftFamilyColumnData = [
  columnHelper.accessor("name", {
    id: "name",
    header: "Aircraft Family",
  }),
  columnHelper.accessor("aircraft_types", {
    id: "aircraft_types",
    header: "Aircraft Types",
    cell: ({ row }) => {
      const aircraftTypes = row.original.aircraft_types;
      if (!aircraftTypes?.length) return "-";

      // Join all aircraft type names with commas
      return (
        <span
          style={{
            display: "inline-block",
            // maxWidth: "350px",
            whiteSpace: "break-spaces",
          }}
        >
          {aircraftTypes?.map((type) => type.name).join(", ")}{" "}
        </span>
      );
    },
    enableSorting: false,
  }),

  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    cell: ({ row, table }) => <RenderRolesActions row={row} table={table} />,
  }),
];

const RenderEngineTypeActions = ({ row, table }) => {
  const { user } = useSelector((state) => state.auth);
  const rolesPermission = user?.permissions[modules.enginetype];
  const { u, d } = permissionsKey;
  return (
    <>
      <Tooltip
        title={tooltTitle.view}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.view(row?.original)}
        >
          <VisibilityIcon fillColor={black} height="18" width="18" />
        </IconButton>
      </Tooltip>
      {checkPermissions(rolesPermission, [u]) && (
        <Tooltip
          title={tooltTitle.edit}
          {...tooltiPlacemetpProps}
          disableInteractive
        >
          <IconButton
            sx={{ py: 0, px: 0.5 }}
            onClick={() => table.options.meta.edit(row?.original)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {checkPermissions(rolesPermission, [d]) && (
        <>
          <Tooltip
            title={tooltTitle.delete}
            {...tooltiPlacemetpProps}
            disableInteractive
          >
            <IconButton
              sx={{ py: 0, px: 0.5 }}
              onClick={() => table.options.meta.delete(row?.original)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip
        title={tooltTitle.history}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.history(row?.original)}
        >
          <HistoryIcon sx={{ color: "var(--primary-color)", fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

// Engine Type column

export const engineTypeColumnData = [
  columnHelper.accessor("name", {
    id: "name",
    header: "Engine Type",
  }),

  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    cell: ({ row, table }) => (
      <RenderEngineTypeActions row={row} table={table} />
    ),
  }),
];

// Render Engine Family

const RenderEngineFamilyActions = ({ row, table }) => {
  const { user } = useSelector((state) => state.auth);
  const rolesPermission = user?.permissions[modules.enginefamily];
  const { u, d } = permissionsKey;
  return (
    <>
      <Tooltip
        title={tooltTitle.view}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.view(row?.original)}
        >
          <VisibilityIcon fillColor={black} height="18" width="18" />
        </IconButton>
      </Tooltip>
      {checkPermissions(rolesPermission, [u]) && (
        <Tooltip
          title={tooltTitle.edit}
          {...tooltiPlacemetpProps}
          disableInteractive
        >
          <IconButton
            sx={{ py: 0, px: 0.5 }}
            onClick={() => table.options.meta.edit(row?.original)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {checkPermissions(rolesPermission, [d]) && (
        <>
          <Tooltip
            title={tooltTitle.delete}
            {...tooltiPlacemetpProps}
            disableInteractive
          >
            <IconButton
              sx={{ py: 0, px: 0.5 }}
              onClick={() => table.options.meta.delete(row?.original)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip
        title={tooltTitle.history}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.history(row?.original)}
        >
          <HistoryIcon sx={{ color: "var(--primary-color)", fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

// Engine Family Column

export const engineFamilyColumnData = [
  columnHelper.accessor("name", {
    id: "name",
    header: "Engine Family",
  }),
  columnHelper.accessor("engine_type", {
    id: "engine_type",
    header: "Engine Type",
    cell: ({ row }) => {
      const engineTypes = row?.original?.engine_types;
      if (!engineTypes?.length) return "-";

      // Join all aircraft type names with commas
      return (
        <span
          style={{
            display: "inline-block",
            // maxWidth: "350px",
            whiteSpace: "break-spaces",
          }}
        >
          {engineTypes?.map((type) => type.name).join(", ")}
        </span>
      );
    },
    enableSorting: false,
  }),

  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    cell: ({ row, table }) => (
      <RenderEngineFamilyActions row={row} table={table} />
    ),
  }),
];

// Render Aircraft Family

const RenderAircraftFamilyActions = ({ row, table }) => {
  const { user } = useSelector((state) => state.auth);
  const rolesPermission = user?.permissions[modules.enginefamily];
  const { u, d } = permissionsKey;
  return (
    <>
      <Tooltip
        title={tooltTitle.view}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.view(row?.original)}
        >
          <VisibilityIcon fillColor={black} height="18" width="18" />
        </IconButton>
      </Tooltip>
      {checkPermissions(rolesPermission, [u]) && (
        <Tooltip
          title={tooltTitle.edit}
          {...tooltiPlacemetpProps}
          disableInteractive
        >
          <IconButton
            sx={{ py: 0, px: 0.5 }}
            onClick={() => table.options.meta.edit(row?.original)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {checkPermissions(rolesPermission, [d]) && (
        <>
          <Tooltip
            title={tooltTitle.delete}
            {...tooltiPlacemetpProps}
            disableInteractive
          >
            <IconButton
              sx={{ py: 0, px: 0.5 }}
              onClick={() => table.options.meta.delete(row?.original)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip
        title={tooltTitle.history}
        {...tooltiPlacemetpProps}
        disableInteractive
      >
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.history(row?.original)}
        >
          <HistoryIcon sx={{ color: "var(--primary-color)", fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

// AircraftType Column

export const aircraftTypesColumnData = [
  columnHelper.accessor("name", {
    id: "name",
    header: "Aircraft Types",
  }),

  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    cell: ({ row, table }) => (
      <RenderAircraftFamilyActions row={row} table={table} />
    ),
  }),
];

// Sent History Alert

export const sentHistoryColumnData = [
  columnHelper.accessor("triggered_at", {
    id: "triggered_at",
    header: "Last Trigger",
    cell: ({ row }) => {
      const triggeredAt = row?.original?.triggered_at;
      const date = moment(triggeredAt).format("MM-DD-YYYY");
      const time = moment(triggeredAt).format("hh:mm A");
      return <>{`${date}, ${time}`}</>;
    },
  }),
  columnHelper.accessor("file_path", {
    id: "file_path",
    header: "File Path",
    cell: ({ row }) => {
      const filePath = row?.original?.file_path;
      const fullPath = `${process.env.REACT_APP_API_BASE_URL}${filePath}`;

      const handleDownload = async () => {
        try {
          const response = await axiosConfig.get(fullPath, {
            responseType: "blob",
          });

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const filename = filePath.split("/").pop();
          link.setAttribute("download", filename);

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Download failed:", error);
        }
      };

      return (
        <Link
          component="button"
          onClick={handleDownload}
          sx={{
            color: "var(--primary-color)",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            textDecoration: "none",
            background: "none",
            border: "none",
            cursor: "pointer",
            padding: "4px 8px",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          <DownloadIcon sx={{ fontSize: 20 }} />
          {/* {filePath.split('/').pop()} */}
        </Link>
      );
    },
  }),
];

// TemplateColumn
// Master Data
export const aircraftMasterHeaderData = [
  columnHelper.accessor("aircraft_familiy", {
    id: "aircraft_familiy",
    header: "Aircraft Family",
    cell: ({ row }) => {
      return <>{row?.original?.aircraft_family?.name}</>;
    },
  }),
  columnHelper.accessor("aircraft_type", {
    id: "aircraft_type",
    header: "Aircraft Type",
    cell: ({ row }) => {
      return <>{row?.original?.aircraft_type?.name}</>;
    },
  }),
  columnHelper.accessor("ata_chapter", {
    id: "ata_chapter",
    header: "ATA Chapter",
  }),
  columnHelper.accessor("part_number", {
    id: "part_number",
    header: "Part Number",
  }),
  columnHelper.accessor("description", {
    id: "description",
    header: "Description",
  }),
  columnHelper.accessor("quantity", {
    id: "quantity",
    header: "Quantity",
  }),
  columnHelper.accessor("category", {
    id: "category",
    header: "Category",
  }),
  columnHelper.accessor("created_at", {
    id: "created_at",
    header: "Created At",
    cell: ({ row }) => {
      const created_at = row?.original?.created_at;
      return <>{dateFormat(created_at)}</>;
    },
  }),
  columnHelper.accessor("updated_at", {
    id: "updated_at",
    header: "Updated At",
    cell: ({ row }) => {
      const updated_at = row?.original?.updated_at;
      return <>{dateFormat(updated_at)}</>;
    },
  }),
  columnHelper.accessor("created_by_name", {
    id: "created_by_name",
    header: "Created By",
  }),
  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    cell: ({ row, table }) => <AircraftMasterActions row={row} table={table} />,
    enableSorting: false,
  }),
];

export const engineMasterHeaderData = [
  columnHelper.accessor("engine_familiy", {
    id: "engine_familiy",
    header: "Engine Family",
    cell: ({ row }) => {
      return <>{row?.original?.engine_family?.name}</>;
    },
  }),
  columnHelper.accessor("engine_type", {
    id: "engine_type",
    header: "Engine Type",
    cell: ({ row }) => {
      return <>{row?.original?.engine_type?.name}</>;
    },
  }),
  columnHelper.accessor("part_number", {
    id: "part_number",
    header: "Part Number",
  }),
  columnHelper.accessor("description", {
    id: "description",
    header: "Description",
  }),
  columnHelper.accessor("quantity", {
    id: "quantity",
    header: "Quantity",
  }),
  columnHelper.accessor("ata_chapter", {
    id: "ata_chapter",
    header: "ATA Chapter",
  }),
  columnHelper.accessor("unit_price", {
    id: "unit_price",
    header: "Unit Price",
    cell: ({ row }) => {
      const unit_price = row?.original?.unit_price;
      return <> {unit_price ? getCurrency(unit_price) : "-"}</>;
    },
  }),
  columnHelper.accessor("benign_scrap_rate", {
    id: "benign_scrap_rate",
    header: "Benign Scrap Rate",
  }),
  columnHelper.accessor("harsh_scrap_rate", {
    id: "harsh_scrap_rate",
    header: "Harsh Scrap Rate",
  }),
  columnHelper.accessor("sales_clp", {
    id: "sales_clp",
    header: "Sales CLP(%)",
  }),
  columnHelper.accessor("category", {
    id: "category",
    header: "Category",
  }),
  columnHelper.accessor("ch5_life_limit", {
    id: "ch5_life_limit",
    header: "CH5 Life Limit",
  }),
  // columnHelper.accessor("clp", {
  //   id: "clp",
  //   header: "CLP",
  // }),
  // columnHelper.accessor("lt", {
  //   id: "lt",
  //   header: "LT",
  // }),
  columnHelper.accessor("module", {
    id: "module",
    header: "Module",
  }),
  // columnHelper.accessor("name", {
  //   id: "name",
  //   header: "Name",
  // }),
  // columnHelper.accessor("upq", {
  //   id: "upq",
  //   header: "UPQ",
  // }),
  columnHelper.accessor("created_by_name", {
    id: "created_by_name",
    header: "Created By",
  }),
  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    cell: ({ row, table }) => <AircraftMasterActions row={row} table={table} />,
    enableSorting: false,
  }),
];

export const templateColumn = [
  columnHelper.accessor("name", {
    id: "template_name",
    header: "Name",
    // cell: (info) => info.getValue(),
    // footer: (info) => info.column.id,
  }),
  columnHelper.accessor("type", {
    id: "type",
    header: "Type",
  }),

  columnHelper.accessor("description", {
    id: "description",
    header: "Description",
  }),
  columnHelper.accessor("created_by_name", {
    id: "created_by_name",
    header: "Created By",
  }),
  columnHelper.accessor("created_at", {
    id: "created_at",
    header: "Created At",
    cell: ({ row }) => {
      const created_at = row?.original?.created_at;
      return <>{dateFormat(created_at) || "-"}</>;
    },
  }),
  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    cell: ({ row, table }) => <RenderTemplateActions row={row} table={table} />,
  }),
];

const renderPermissionCell = (permission, moduleData, table) => {
  const permissionId = moduleData.availablePermissions.get(permission);
  if (!permissionId) return "NA";
  const onPermissionChange = table.options.meta.select;
  const selectedPermissions = table.options.meta.selectedPermissionIds;
  const isDisable = table.options.meta.disableCheckbox;

  return (
    <Checkbox
      checked={selectedPermissions.includes(permissionId)}
      onChange={(e) => {
        onPermissionChange(permissionId, e.target.checked);
      }}
      sx={checkBoxStyle}
      size="small"
      disabled={isDisable}
    />
  );
};

export const permissionMatrixColumnData = [
  columnHelper.accessor("module", {
    header: "Features",
    cell: ({ row, table }) => <>{capitalizeAndFormat(row?.original?.module)}</>,
  }),
  columnHelper.accessor("read", {
    header: "Read",
    cell: ({ row, table }) => renderPermissionCell("R", row.original, table),
  }),
  columnHelper.accessor("create", {
    header: "Create",
    cell: ({ row, table }) => renderPermissionCell("C", row.original, table),
  }),
  columnHelper.accessor("update", {
    header: "Update",
    cell: ({ row, table }) => renderPermissionCell("U", row.original, table),
  }),
  columnHelper.accessor("delete", {
    header: "Delete",
    cell: ({ row, table }) => renderPermissionCell("D", row.original, table),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: ({ row, table }) => renderPermissionCell("S", row.original, table),
  }),
  columnHelper.accessor("export", {
    header: "Export",
    cell: ({ row, table }) => renderPermissionCell("E", row.original, table),
  }),
  columnHelper.accessor("import", {
    header: "Import",
    cell: ({ row, table }) => renderPermissionCell("I", row.original, table),
  }),
];

export const applookupColumn = [
  columnHelper.accessor("application_code", {
    id: "application_code",
    header: "Application Code",
  }),
  columnHelper.accessor("application_master", {
    id: "application_master",
    header: "Application Master",
  }),
  columnHelper.accessor("supply_chain_group", {
    id: "supply_chain_group",
    header: "Supply Chain Group",
  }),
];

export const ilsHeaderData = [
  columnHelper.accessor("part_number", {
    id: "part_number",
    header: "Part Number",
    cell: ({ row }) => {
      return <>{row?.original?.part_number}</>;
    },
  }),

  columnHelper.accessor("description", {
    id: "description",
    header: "Description",
    cell: ({ row }) => {
      return <Expandable content={row?.original?.description} />;
    },
  }),

  columnHelper.accessor("fmv_ne", {
    id: "fmv_ne",
    header: "FMV NE",
    cell: ({ row }) => {
      const fmv_ne = row?.original?.fmv_ne;
      return <>{getCurrency(fmv_ne)}</>;
    },
  }),

  columnHelper.accessor("", {
    id: "Asset ( Family / Type )",
    header: "Aircraft Family / Engine Family",
    cell: ({ row }) => {
      const asset_aircraft_family = row?.original?.aircraft_family?.name;
      const asset_engine_family = row?.original?.engine_family?.name;

      return (
        <>
          <Box>
            <Typography
              sx={{
                paddingLeft: "10px",
                fontWeight: "var(--font-medium)",
                fontSize: "var(--font-size-13)",
              }}
            >
              {asset_aircraft_family} / {asset_engine_family}
            </Typography>
          </Box>
        </>
      );
    },
  }),
  // columnHelper.accessor("aircraft_family", {
  //   id: "aircraft_family",
  //   header: "Aircraft Family",
  //   cell: ({ row }) => {
  //     return <>{row?.original?.aircraft_family?.name}</>;
  //   },
  //   enableSorting: false,
  // }),
  // columnHelper.accessor("engine_family", {
  //   id: "engine_family",
  //   header: "Engine Family",
  //   cell: ({ row }) => {
  //     return <>{row?.original?.engine_family?.name}</>;
  //   },
  //   enableSorting: false,
  // }),

  columnHelper.accessor("created_at", {
    id: "created_at",
    header: "Created At",
    cell: ({ row }) => {
      const created_at = row?.original?.created_at;
      return <>{dateFormat(created_at)}</>;
    },
  }),

  columnHelper.accessor("ata_groups", {
    id: "ata_groups",
    header: "ATA Groups",
    cell: ({ row }) => {
      const ata_groups = row?.original?.ata_groups;
      return <>{ata_groups}</>;
    },
  }),

  columnHelper.accessor("ata_chapters", {
    id: "ata_chapters",
    header: "ATA Chapters",
    cell: ({ row }) => {
      const ata_chapters = row?.original?.ata_chapters;
      return <>{ata_chapters}</>;
    },
  }),
  columnHelper.accessor("ipc_reference", {
    id: "ipc_reference",
    header: "IPC Reference",
    cell: ({ row }) => {
      const ipc_reference = row?.original?.ipc_reference;
      return <Expandable content={ipc_reference} />;
    },
  }),

  columnHelper.accessor("repairable", {
    id: "repairable",
    header: "Repairable",
    cell: ({ row }) => {
      const repairable = row?.original?.repairable;
      return <>{repairable}</>;
    },
  }),

  columnHelper.accessor("qty_per_aircraft", {
    id: "qty_per_aircraft",
    header: "Qty per Aircraft",
    cell: ({ row }) => {
      const qty_per_aircraft = row?.original?.qty_per_aircraft;
      return <>{qty_per_aircraft}</>;
    },
  }),

  columnHelper.accessor("fmv_ne", {
    id: "fmv_ne",
    header: "FMV NE",
    cell: ({ row }) => {
      const fmv_ne = row?.original?.fmv_ne;
      return <>{getCurrency(fmv_ne)}</>;
    },
  }),

  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    cell: ({ row, table }) => <RenderIlsActions row={row} table={table} />,
    enableSorting: false,
  }),
];
