import React from "react";
import { Box, Skeleton } from "@mui/material";
import TableSkeleton from "./TableSkeleton";
import { columnData } from "../../data/table-data/settingsTableData";

const DashboardLayoutSkeleton = ({ isSidebar = true, isHeader = true }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {/* Header - Now full width */}
      {isHeader && (
        <Box
          sx={{
            height: "48px",
            bgcolor: "var(--blackhaze-color)",
            borderBottom: "1px solid var(--alto-color)",
            display: "flex",
            alignItems: "center",
            px: 3,
            justifyContent: "space-between",
            position: "fixed",
            width: "100%",
            zIndex: 1100,
          }}
        >
          {/* Logo in Header */}
          <Skeleton
            variant="rectangular"
            width={150}
            height={40}
            sx={{ bgcolor: "var(--alto-color)" }}
          />

          {/* Header Right */}
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Skeleton variant="circular" width={32} height={32} />
            <Skeleton variant="rectangular" width={120} height={24} />
          </Box>
        </Box>
      )}

      {/* Main Content Container */}
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          mt: isHeader && isSidebar ? "48px" : "0px",
        }}
      >
        {/* Permanent Drawer */}
        {isSidebar && (
          <Box
            sx={{
              width: "80px",
              bgcolor: "var(--blackhaze-color)",
              borderRight: "1px solid var(--alto-color)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pt: 2,
              gap: 3,
            }}
          >
            {/* Nav Items */}
            {[1, 2, 3, 4, 5, 6, 7].map((item) => (
              <Skeleton
                key={item}
                variant="rectangular"
                width={40}
                height={40}
                sx={{ bgcolor: "var(--alto-color)" }}
              />
            ))}
          </Box>
        )}

        {/* Main Content */}
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {/* Table Content */}
          <Box sx={{ flex: 1, p: 3 }}>
            {/* Table */}
            <TableSkeleton columns={columnData} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayoutSkeleton;
