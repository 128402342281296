import { lazy } from "react";

// rootroute
export const LazyRootRoute = lazy(
  () => import("../components/route/RootRoute")
);
export const LazyMainLayout = lazy(
  () => import("../components/layout/main-layout/Layout")
);

// layouts
export const LazyAuthLayout = lazy(
  () => import("../components/layout/auth-layout/AuthLayout")
);

// auth pages
export const LazyLogin = lazy(() => import("../pages/auth/login/Login"));
export const LazyForgotPassword = lazy(
  () => import("../pages/auth/forgot-password/ForgotPassword")
);
export const LazyTerms = lazy(() => import("../pages/auth/terms/Terms"));
export const LazyResetPassword = lazy(
  () => import("../pages/auth/reset-password/ResetPassword")
);
export const LazyAzureLogin = lazy(
  () => import("../pages/auth/login/AzureLogin")
);
export const LazyAzureLogout = lazy(
  () => import("../pages/auth/logout/AuzureLogout")
);

// private pages
export const LazyHome = lazy(() => import("../pages/home/Home"));
export const LazyPipeline = lazy(() => import("../pages/pipeline/Pipeline"));
export const LazyPipelineDetails = lazy(
  () => import("../pages/pipeline/details/PipelineDetails")
);
export const LazyHarvestList = lazy(
  () => import("../pages/pipeline/harvest-list/HarvestList")
);

export const LazyAircraftBasicDetail = lazy(
  () =>
    import(
      "../pages/pipeline/harvest-list/aircraft/aircraft-basic-detail/AircraftBasicDetail"
    )
);
export const LazyEngineBasicDetail = lazy(
  () =>
    import(
      "../pages/pipeline/harvest-list/engine/engine-basic-detail/EngineAssetDetail"
    )
);
export const LazyLLPComponent = lazy(
  () =>
    import(
      "../pages/pipeline/harvest-list/engine/engine-components/LLPComponent"
    )
);

export const LazyInventory = lazy(
  () => import("../pages/inventory/InventoryLayout")
);
export const LazyInventoryList = lazy(
  () => import("../pages/inventory/inventories-list/InventoriesList")
);

export const LazyPipelineTeardown = lazy(
  () =>
    import(
      "../pages/inventory/pipeline-teardown-inventories/PipelineTeardownInventories"
    )
);
export const LazyUnderStock = lazy(
  () =>
    import("../pages/inventory/inventories-under-stock/InventoriesUnderStock")
);

export const LazyInRepair = lazy(
  () => import("../pages/inventory/inventories-inrepair/InventoriesInRepair")
);
export const LazySlowMovingInventories = lazy(
  () =>
    import("../pages/inventory/slow-moving-inventories/SlowMovingInventories")
);
export const LazyInventoryDetails = lazy(
  () =>
    import("../pages/inventory/inventories-details/InventoriesDetailsLayout")
);
export const LazyGofoPartPurchase = lazy(
  () =>
    import(
      "../pages/inventory/inventories/go-for-part-purchase/GofoPartPurchaseLayout"
    )
);
export const LazyVendor = lazy(
  () => import("../pages/vendor-management/VendorManagement")
);
export const LazyVendorPurchaseOrder = lazy(
  () => import("../pages/vendor-management/tabs/PurchaseOrder")
);
export const LazyVendorSalesOrder = lazy(
  () => import("../pages/vendor-management/tabs/SalesOrder")
);
export const LazyVendorCustomerQuote = lazy(
  () => import("../pages/vendor-management/tabs/CustomerQuote")
);
export const LazyVendorVendorQuote = lazy(
  () => import("../pages/vendor-management/tabs/VendorQuote")
);
export const LazyVendorInvoice = lazy(
  () => import("../pages/vendor-management/tabs/Invoices")
);
export const LazyVendReapirOrder = lazy(
  () => import("../pages/vendor-management/tabs/RepairOrder")
);
export const LazyVendorDetail = lazy(
  () => import("../pages/vendor-management/VendorManagementDetail")
);
export const LazyAnalytics = lazy(() => import("../pages/analytics/Analytics"));
export const LazyReports = lazy(() => import("../pages/reports/Reports"));
export const LazySettingsLayout = lazy(
  () => import("../pages/settings/settings-layout/SettingsLayout")
);
export const LazyUsersSettings = lazy(
  () => import("../pages/settings/users/UsersSettings")
);
export const LazyRolesSettings = lazy(
  () => import("../pages/settings/roles/RolesSettings")
);
export const LazyAlertsSettings = lazy(
  () => import("../pages/settings/alerts/Alerts")
);
export const LazyGraphSettings = lazy(
  () => import("../pages/settings/graph/Graph")
);

export const LazyTemplateSetting = lazy(
  () => import("../pages/settings/template/Template")
);

export const LazyAircraftTypesSetting = lazy(
  () => import("../pages/settings/aircraft-types/AircraftTypes")
);

export const LazyAircraftFamilySetting = lazy(
  () => import("../pages/settings/aircraft-family/AircraftFamily")
);

export const LazyEngineFamilySetting = lazy(
  () => import("../pages/settings/engine-family/EngineFamily")
);

export const LazyAircraftMaster = lazy(
  () => import("../pages/settings/master-data/aircraft/AircraftMaster")
);

export const LazyEngineTypes = lazy(
  () => import("../pages/settings/engine-types/EngineTypes")
);

export const LazyEngineMaster = lazy(
  () => import("../pages/settings/master-data/engine/EngineMaster")
);
export const LazyProfileSettings = lazy(
  () => import("../pages/settings/profile/ProfileSettings")
);

export const LazyChangePassword = lazy(
  () => import("../pages/settings/change-password/ChangePassword")
);

export const LazyAppMasterLookup = lazy(
  () => import("../pages/settings/app-lookup/AppMasterLookup")
);

export const LazyILS = lazy(() => import("../pages/settings/ils/ILS"));

export const Lazy404Page = lazy(
  () => import("../pages/404error-page/NotFoundPage")
);

export const LazyComingSoon = lazy(
  () => import("../components/coming-soon/ComingSoon")
);

// harvest list tabs lazy imports

export const LazyHarvestListTable = lazy(
  () => import("../pages/pipeline/harvest-list/aircraft/HarvestListTable")
);

export const LazyInventoryCheck = lazy(
  () =>
    import(
      "../pages/pipeline/harvest-list/aircraft/inventory-check/InventoryCheck"
    )
);

export const LazyPricing = lazy(
  () => import("../pages/pipeline/harvest-list/aircraft/Pricing")
);

export const LazyCashflow = lazy(
  () =>
    import(
      "../pages/pipeline/harvest-list/aircraft/cashflow-forecast/CashflowForecast"
    )
);

export const LazyEBIDTA = lazy(
  () => import("../pages/pipeline/harvest-list/aircraft/EBITDA/AircraftEBITDA")
);

export const LazyEngineHarvestList = lazy(
  () => import("../pages/pipeline/harvest-list/engine/EngineHarvestList")
);

export const LazyEngineInventoryCheck = lazy(
  () =>
    import(
      "../pages/pipeline/harvest-list/engine/inventory-check/InventoryCheck"
    )
);

export const LazyEnginePricing = lazy(
  () => import("../pages/pipeline/harvest-list/engine/pricing/Pricing")
);

export const LazyEngineCashflow = lazy(
  () =>
    import(
      "../pages/pipeline/harvest-list/engine/cashflow-forecast/EngineCahflow"
    )
);

export const LazyEngineEbidta = lazy(
  () => import("../pages/pipeline/harvest-list/engine/EBITA/EngineEBITA")
);

// Flip Trade Lazy Imports

export const LazyFlipTrade = lazy(
  () => import("../pages/flip-trading/FlipTrading")
);
export const LazyFlipTradeParts = lazy(
  () => import("../pages/flip-trading/FlipTradingParts")
);

// engine-inventorycheck childrn

export const LazyLLPInventoryCheck = lazy(
  () =>
    import(
      "../pages/pipeline/harvest-list/engine/inventory-check/LLPInventoryCheck"
    )
);

export const LazyNonLLPInventoryCheck = lazy(
  () =>
    import(
      "../pages/pipeline/harvest-list/engine/inventory-check/NonLLPInventoryCheck"
    )
);

export const LazyLRUInventoryCheck = lazy(
  () =>
    import(
      "../pages/pipeline/harvest-list/engine/inventory-check/LRUInventoryCheck"
    )
);

export const LazyLLPPricing = lazy(
  () =>
    import("../pages/pipeline/harvest-list/engine/pricing/PricingLLPComponent")
);

export const LazyNonLLPPricing = lazy(
  () =>
    import(
      "../pages/pipeline/harvest-list/engine/pricing/PricingNonLLPComponent"
    )
);

export const LazyLRUPricing = lazy(
  () =>
    import("../pages/pipeline/harvest-list/engine/pricing/PricingLRUComponent")
);

export const LazyDemandForecast = lazy(
  () => import("../pages/inventory/demand-forecast/DemandForecast")
);
