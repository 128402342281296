import { createSlice } from "@reduxjs/toolkit";
import {
  addFliptrading,
  updateFliptrading,
  getFliptrade,
  deletePartRow,
} from "./fliptradeAction";

export const initialState = {
  fliptradeInitialLoad: true,
  addLoading: false,
  loading: false,
  fliptrade: {},
};

const fliptradingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setFliptradeInitalLoad: (state, action) => {
      state.fliptradeInitialLoad = action.payload;
    },
    setFlipTradeData: (state, action) => {
      state.fliptrade = { ...state.fliptrade, data: action?.payload };
    },
    clearFliptradingData: (state) => {
      state.addLoading = false;
      state.loading = false;
      state.fliptrade = {};
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getFliptrade.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFliptrade.fulfilled, (state, action) => {
        state.loading = false;
        state.fliptradeInitialLoad = false;
        state.fliptrade = action.payload;
      })
      .addCase(getFliptrade.rejected, (state) => {
        state.loading = false;
        state.fliptradeInitialLoad = false;
        state.fliptrade = {};
      })
      // Add

      .addCase(addFliptrading.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addFliptrading.fulfilled, (state, action) => {
        state.addLoading = false;
        state.formErrors = {};
      })

      .addCase(addFliptrading.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // PATCH

      .addCase(updateFliptrading.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateFliptrading.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(updateFliptrading.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors = action.payload?.message || {};
      })

      // DELETE PARTS ROW (PATCH TYPE METHOD)

      .addCase(deletePartRow.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deletePartRow.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(deletePartRow.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors = action.payload?.message || {};
      });
  },
});

export const {
  clearFliptradingData,
  setFliptradeInitalLoad,
  setFlipTradeData,
} = fliptradingSlice.actions;

export default fliptradingSlice.reducer;
