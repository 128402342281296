import {
  AnalyticsIcon,
  HomeIcon,
  InventoryIcon,
  PipelineIcon,
  ReportsIcons,
  SettingsIcon,
  VendorsIcon,
  FlipTradeIcon,
} from "../components/svg";
import {
  active,
  awarded,
  closed,
  in_progress,
  inactive,
  ongoing,
  pending,
} from "../constant/constant";

export const modules = {
  home: "home",
  projects: "projects",
  inventory: "inventory",
  vendors: "vendors",
  analytics: "analytics",
  reports: "reports",
  users: "users",
  roles: "roles",
  alerts: "alerts",
  templates: "templates",
  profile: "profile",
  change_password: "changepassword",
  graph: "graphs",
  aircraftmaster: "aircraftmaster",
  enginemaster: "enginemaster",
  aircraftfamily: "aircraftfamily",
  enginefamily: "enginefamily",
  aircrafttype: "aircrafttype",
  fliptrading: "fliptrading",
  enginetype: "enginetype",
  app_master_lookup: "applicationmasterlookup",
  ils: "ils",
};
const {
  home,
  projects,
  inventory,
  vendors,
  analytics,
  reports,
  users,
  roles,
  alerts,
  graph,
  templates,
  profile,
  change_password,
  aircraftmaster,
  enginemaster,
  aircraftfamily,
  aircrafttype,
  enginetype,
  enginefamily,
  fliptrading,
  app_master_lookup,
  ils,
} = modules;

export const permissionsKey = {
  c: "C",
  r: "R",
  u: "U",
  d: "D",
  e: "E",
  i: "I",
  s: "S",
};

export const menuItems = [
  {
    text: "Home",
    icon: HomeIcon,
    path: "/home",
    hasNestedRoutes: true,
    module: [home],
  },
  {
    text: "Projects",
    icon: PipelineIcon,
    path: "/projects",
    module: [projects],
  },
  {
    text: "Inventory",
    icon: InventoryIcon,
    path: "/inventory",
    module: [inventory],
  },

  {
    text: "Vendors Management",
    icon: VendorsIcon,
    path: "/vendors",
    module: [vendors],
  },
  {
    text: "Flip Trading",
    icon: FlipTradeIcon,
    path: "/fliptrading",
    module: [fliptrading],
  },
  {
    text: "Analytics",
    icon: AnalyticsIcon,
    path: "/analytics",
    module: [analytics],
  },
  // {
  //   text: "Reports",
  //   icon: ReportsIcons,
  //   path: "/reports",
  //   module: [reports],
  // },
  {
    text: "Settings",
    icon: SettingsIcon,
    path: "/settings",
    module: [
      users,
      roles,
      alerts,
      templates,
      aircraftmaster,
      enginemaster,
      graph,
      profile,
      change_password,
      app_master_lookup,
      ils,
    ],
  },
];

export const settingsMenuItems = [
  { text: "Users", path: "/settings/users", module: [users] },
  { text: "Roles", path: "/settings/roles", module: [roles] },
  { text: "Alerts", path: "/settings/alerts", module: [alerts] },
  { text: "Template", path: "/settings/template", module: [templates] },
  //  { text: "Graphs", path: "/settings/graph", module: [graph] },
  {
    text: "Aircraft Family & Types",
    path: "/settings/aircraft-family",
    module: [aircraftfamily],
  },
  //  {
  //    text: "Aircraft Types",
  //    path: "/settings/aircraft-types",
  //    module: [aircrafttype],
  //  },
  //  {
  //    text: "Engine Types",
  //    path: "/settings/engine-types",
  //    module: [enginetype],
  //  },
  {
    text: "Engine Family & Types",
    path: "/settings/engine-family",
    module: [enginefamily],
  },
  {
    text: "Aircraft Parts Master",
    path: "/settings/aircraft-master",
    module: [aircraftmaster],
  },
  {
    text: "Engine Parts Master",
    path: "/settings/engine-master",
    module: [enginemaster],
  },
  {
    text: "Application Master Lookup",
    path: "/settings/application-master-lookup",
    module: [app_master_lookup],
  },
  {
    text: "ILS",
    path: "/settings/ils",
    module: [ils],
  },
];

export const profileSettingsMenuItems = [
  { text: "Profile", path: "/settings/profile", module: [profile] },
  {
    text: "Change Password",
    path: "/settings/change-password",
    module: [change_password],
    state: true,
  },
];

export const headerMenuItems = [
  ...profileSettingsMenuItems,

  { text: "Logout" },
];

export const accessOptions = [
  { value: "super_admin", label: "Super Admin" },
  { value: "sc", label: "SC Admin" },
  { value: "read", label: "Read" },
  { value: "write", label: "Write" },
  { value: "admin", label: "Admin" },
];

export const statusOptions = [
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "Inactive",
  },
];

export const graphStatusOptions = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "Inactive",
  },
];

export const exportMenuData = [
  { text: "EXCEL" },
  // { text: "PDF" },
  // { text: "DOC" },
];

export const matchSortKey = {
  created_by: "created_by__name",
  updated_by: "updated_by__name",
  roles: "roles__role_name",
  created_by_name: "created_by__name",
  aircraft_familiy: "aircraft_family__name",
  engine_familiy: "engine_family__name",
  msn_esn_number: "project_asset__msn_esn",
  typical_pn: "part_number",
};

export const allowedRoutes = ["/", "/access", "/logout"];

export const openMainLayoutRoutes = ["/access", "/logout"];

export const assetType = [
  { label: "Aircraft ", value: "Aircraft" },
  { label: "Engine", value: "Engine" },
];
export const status = [
  { label: "Ongoing", value: "ongoing" },
  { label: "Closed", value: "closed" },
  { label: "Awarded", value: "awarded" },
];

export const importMenuItems = [
  { text: "Update Harvest List", value: "append" },
  { text: "Clear & Add Harvest List", value: "update" },
];

export const statusColorLable = [
  { id: 1, label: "Under stock", color: "#CEE741" },
  { id: 2, label: "In stock", color: "#FEF445" },
  { id: 3, label: "Over stock", color: "#F86E00" },
];

export const matchStatusStyle = {
  under_stock: "#CEE741",
  in_stock: "#FEF445",
  over_stock: "#F86E00",
};

export const tooltiPlacemetpProps = {
  placement: "top",
};

export const matchStatusStyleTable = {
  [active]: {
    backgroundColor: "var(--seagreen-color)",
    border: "1px solid var(--emerald-color)",
  },
  [in_progress]: {
    backgroundColor: "var(--tumeric-color)",
    border: "1px solid var(--gold-color)",
  },
  [ongoing]: {
    backgroundColor: "var(--tumeric-color)",
    border: "1px solid var(--gold-color)",
  },
  [awarded]: {
    backgroundColor: "var(--seagreen-color)",
    border: "1px solid var(--emerald-color)",
  },
  [pending]: {
    backgroundColor: "var(--tumeric-color)",
    border: "1px solid var(--gold-color)",
  },
  [inactive]: {
    backgroundColor: "var(--copper-color)",
    border: "1px solid var(--brown-color)",
  },
  [closed]: {
    backgroundColor: "var(--copper-color)",
    border: "1px solid var(--brown-color)",
  },
};

export const matchStatus = {
  in_progress: "In-Progress",
  ongoing: "Ongoing",
  closed: "Closed",
  awarded: "Awarded",
};

export const repairDropdown = [
  {
    label: "-",
    value: "",
  },
  {
    label: "Repair",
    value: "Repair",
  },
  {
    label: "Exchange",
    value: "Exchange",
  },
];
export const inClude = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

export const assumedOptions = [
  { label: "None", value: 1 },
  { label: "Bare", value: 2 },
  { label: "Neutral", value: 3 },
  { label: "Full", value: 4 },
  { label: "Est. Value", value: 5 },
  { label: "Listed Below", value: 6 },
];

export const partOptions = [
  { label: "None", value: 1 },
  { label: "Fan Disk", value: 2 },
  { label: "Booster Spool", value: 3 },
  { label: "Fan Shaft", value: 4 },
];

export const moduleOtions = [
  { label: "None", value: 1 },
  { label: "LPC", value: 2 },
  { label: "HPC", value: 3 },
  { label: "HPT", value: 4 },
  { label: "LPT", value: 5 },
];

export const matchCondition = ["AR", "ar", "aR", "Ar"];

export const compareOptions = [
  {
    label: "Equal",
    value: "equal",
  },
  {
    label: "Not Equal",
    value: "not_equal",
  },
  {
    label: "IN",
    value: "in",
  },
  {
    label: "Not In",
    value: "not_in",
  },
  {
    label: "Less",
    value: "less",
  },
  {
    label: "Less or Equal",
    value: "less_or_equal",
  },
  {
    label: "Greater",
    value: "greater",
  },
  {
    label: "Greater or Equal",
    value: "greater_or_equal",
  },
  {
    label: "Between",
    value: "between",
  },
  {
    label: "Not Between",
    value: "not_between",
  },
  {
    label: "Is Null",
    value: "is_null",
  },
  {
    label: "Is Not Null",
    value: "is_not_null",
  },
];

export const singleFieldOperators = [
  "equal",
  "not_equal",
  "less",
  "less_or_equal",
  "greater",
  "greater_or_equal",
  "in",
  "not_in",
];

export const doubleFieldOperators = ["between", "not_between"];

export const noFieldOperators = ["is_null", "is_not_null"];

export const getFmvValue = ({
  fmv_operator,
  fmv_value,
  fmv_start,
  fmv_end,
}) => {
  if (noFieldOperators.includes(fmv_operator)) {
    return fmv_operator === "is_null";
  }
  if (doubleFieldOperators.includes(fmv_operator)) {
    return [fmv_start, fmv_end];
  }
  if (singleFieldOperators.includes(fmv_operator)) {
    return fmv_value;
  }
  return fmv_value;
};
