import axiosConfig from "../../../config/axiosConfig";
import { HARVEST_LIST_URL } from "../../../config/config";
import { createQueryParams, getStringFromArray } from "../../../utils/helper";

export const UploadHarvestListService = ({
  data,
  type,
  slug,
  action,
  is_engine_llp,
  is_engine_non_llp,
  is_engine_lru,
}) => {
  const queryString = createQueryParams({
    action,
    is_engine_llp,
    is_engine_non_llp,
    is_engine_lru,
  });
  const url = `${HARVEST_LIST_URL}/${type}/${slug}${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.post(url, data);
};

export const GetHarvestListService = (params) => {
  try {
    const {
      page,
      per_page,
      ordering,
      type,
      slug,
      part_number,
      serial_number,
      description,
      part_class_description,
      quantity,
      ata_chapter,
      condition,
      category,
    } = params || {};

    const paramsList = {
      page,
      per_page,
      ordering,
      part_number,
      serial_number,
      description,
      part_class_description,
      quantity,
      ata_chapter,
      condition: getStringFromArray(condition),
      category: getStringFromArray(category),
    };

    const queryString = createQueryParams(paramsList);

    const url = `${HARVEST_LIST_URL}/${type}/${slug}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const GetHarvestListComponenetsService = (params) => {
  try {
    const {
      page,
      per_page,
      ordering,
      module_type,
      type,
      slug,
      show_unmatched,
      aircraft_part_number,
      serial_number,
      aircraft_description,
      part_class_description,
      ata_chapter,
      mod,
      ipc_ref,
      typical_pn,
      tier,
      benign_scrap_rate,
      expected_yield,
      clp,
      sales_total,
      description,
      engine_description,
      engine_part_number,
      actual_pn,
    } = params || {};

    const paramsList = {
      page,
      per_page,
      ordering,
      show_unmatched,
      aircraft_part_number,
      serial_number,
      aircraft_description,
      part_class_description: getStringFromArray(part_class_description),
      ata_chapter,
      mod,
      ipc_ref,
      typical_pn,
      tier: getStringFromArray(tier),
      benign_scrap_rate,
      expected_yield,
      clp,
      sales_total,
      description,
      engine_description,
      actual_pn,
      engine_part_number,
    };

    const queryString = createQueryParams(paramsList);

    const url = `${HARVEST_LIST_URL}/${module_type}/components-check/${slug}/${type}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};
