import {
  ADD_ROLE_TYPE,
  ADD_TEMPLATE_TYPE,
  ADD_USER_TYPE,
  ALTERNATE_ROLE_TYPE,
  CHANGE_PASSWORD_TYPE,
  DELETE_ROLE_TYPE,
  DELETE_TEMPLATE_TYPE,
  DELETE_USER_TYPE,
  GET_AUDIT_LOGS_ROLE_TYPE,
  GET_AUDIT_LOGS_USER_TYPE,
  GET_FEATURES_TYPE,
  GET_ROLES_TYPE,
  GET_TEMPLATE_TYPE,
  GET_USERS_TYPE,
  UPDATE_PROFILE_TYPE,
  UPDATE_ROLE_TYPE,
  UPDATE_TEMPLATE_TYPE,
  UPDATE_USER_TYPE,
  GET_ANALYTICS_GRAPHS_TYPE,
  UPDATE_ANALYTICS_GRAPH_TYPE,
  ADD_ANALYTICS_GRAPH_TYPE,
  DELETE_ANALYTICS_GRAPH_TYPE,
  GET_ALERT_TYPE,
  ADD_ALERT_TYPE,
  UPDATE_ALERT_TYPE,
  DELETE_ALERT_TYPE,
  ADD_AIRCRAFT_MASTER_TYPE,
  GET_AIRCRAFT_MASTER_TYPE,
  DELETE_AIRCRAFT_MASTER_TYPE,
  GET_AUDIT_LOG_ALERT_TYPE,
  GET_TRIGGER_ALERT_TYPE,
  ADD_ENGINE_MASTER_TYPE,
  GET_AIRCRAFT_FAM_TYPE,
  ADD_AIRCRAFT_FAM_TYPE,
  UPDATE_AIRCRAFT_FAM_TYPE,
  DELETE_AIRCRAFT_FAM_TYPE,
  GET_ENGINE_FAMILY_TYPE,
  DELETE_ENGINE_FAMILY_TYPE,
  UPDATE_ENGINE_FAMILY_TYPE,
  CREATE_ENGINE_FAMILY_TYPE,
  GET_AIRCRAFT_TYPES,
  CREATE_AIRCRAFT_TYPES,
  UPDATE_AIRCRAFT_TYPES,
  DELETE_AIRCRAFT_TYPES,
  GET_ENGINE_MASTER_TYPE,
  DELETE_ENGINE_MASTER_DATA_TYPE,
  GET_ENGINE_TYPE,
  ADD_ENGINE_TYPE,
  UPDATE_ENGINE_TYPE,
  DELETE_ENGINE_TYPE,
  MULTI_EGINE_FAMILY_TYPE,
  UPDATE_MULTI_ENGINE_FAMILY_TYPE,
  GET_APP_LOOKUP_TYPE,
  GET_ILS_TYPE,
  ADD_ILS_TYPE,
  DELETE_ILS_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  AddRoleService,
  AddTemplateService,
  AddUserService,
  AlternateRoleService,
  ChangePasswordService,
  DeleteRoleService,
  DeleteTemplateService,
  DeleteUserService,
  GetAuditLogRoleService,
  GetAuditLogUserService,
  GetFeaturesService,
  GetRolesService,
  GetTemplateService,
  GetUserService,
  UpdateRoleService,
  UpdateTemplatetService,
  updateUserProfileService,
  UpdateUserService,
  GetAnalyticsGraphsService,
  AddAnalyticsGraphService,
  UpdateAnalyticsGraphService,
  DeleteAnalyticsGraphService,
  GetAlertService,
  UpdateAlertService,
  AddAlertService,
  DeleteAlertService,
  AddAircraftMasterDataService,
  GetAircraftMasterDataService,
  DeleteMasterDataRecord,
  GetAuditLogAlertService,
  GetSentHistoryAlert,
  AddeEngineMasterDataService,
  GetAircraftFamilyService,
  AddAircraftFamilyService,
  UpdateAircraftFamilyService,
  GetEngineFamilyService,
  DeleteEngineFamily,
  UpdateEngineFamily,
  AddEngineFamilyService,
  GetAircraftTypesService,
  UpdateAircraftTypeService,
  AddAircraftTypeService,
  DeleteAircraftTypeService,
  GetEngineMasterService,
  DeleteEngineMasterDataRecordService,
  GetEngineTypesService,
  AddEngineTypeService,
  UpdateEngineTypeService,
  DeleteEngineTypeService,
  AddEngineFamilyAndTypesService,
  UpdateEngineFamilyAndTypesService,
  DeleteAircraftFamilyService,
  GetAppLookupService,
  GetIlsService,
  AddIlsMasterService,
  deleteIlsService,
} from "./settingsServices";

export const getUsers = createAppAsyncThunk(GET_USERS_TYPE, GetUserService, {});

export const addUser = createAppAsyncThunk(ADD_USER_TYPE, AddUserService, {
  successMessage: { show: true },
  errorMessage: { show: true },
});

export const updateUser = createAppAsyncThunk(
  UPDATE_USER_TYPE,
  UpdateUserService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const deleteUser = createAppAsyncThunk(
  DELETE_USER_TYPE,
  DeleteUserService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const getRoles = createAppAsyncThunk(
  GET_ROLES_TYPE,
  GetRolesService,
  {}
);

export const addRole = createAppAsyncThunk(ADD_ROLE_TYPE, AddRoleService, {
  successMessage: { show: true },
  errorMessage: { show: true },
});

export const updateRole = createAppAsyncThunk(
  UPDATE_ROLE_TYPE,
  UpdateRoleService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const deleteRole = createAppAsyncThunk(
  DELETE_ROLE_TYPE,
  DeleteRoleService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const assignAlternateRole = createAppAsyncThunk(
  ALTERNATE_ROLE_TYPE,
  AlternateRoleService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const changePassword = createAppAsyncThunk(
  CHANGE_PASSWORD_TYPE,
  ChangePasswordService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: {
      show: true,
    },
  }
);

export const getFeatures = createAppAsyncThunk(
  GET_FEATURES_TYPE,
  GetFeaturesService,
  {}
);

export const updateUserProfile = createAppAsyncThunk(
  UPDATE_PROFILE_TYPE,
  updateUserProfileService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: {
      show: true,
    },
  }
);

export const getAuditLogsUser = createAppAsyncThunk(
  GET_AUDIT_LOGS_USER_TYPE,
  GetAuditLogUserService
);

export const getAuditLogsRole = createAppAsyncThunk(
  GET_AUDIT_LOGS_ROLE_TYPE,
  GetAuditLogRoleService
);

export const addTemplate = createAppAsyncThunk(
  ADD_TEMPLATE_TYPE,
  AddTemplateService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: {
      show: true,
    },
  }
);

export const updateTemplate = createAppAsyncThunk(
  UPDATE_TEMPLATE_TYPE,
  UpdateTemplatetService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const getTemplate = createAppAsyncThunk(
  GET_TEMPLATE_TYPE,
  GetTemplateService,
  {}
);

export const deleteTemplates = createAppAsyncThunk(
  DELETE_TEMPLATE_TYPE,
  DeleteTemplateService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const getAnalyticsGraphs = createAppAsyncThunk(
  GET_ANALYTICS_GRAPHS_TYPE,
  GetAnalyticsGraphsService,
  {}
);

export const addAnalyticsGraph = createAppAsyncThunk(
  ADD_ANALYTICS_GRAPH_TYPE,
  AddAnalyticsGraphService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const updateAnalyticsGraph = createAppAsyncThunk(
  UPDATE_ANALYTICS_GRAPH_TYPE,
  UpdateAnalyticsGraphService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const deleteAnalyticsGraph = createAppAsyncThunk(
  DELETE_ANALYTICS_GRAPH_TYPE,
  DeleteAnalyticsGraphService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

// Alert

export const getAlert = createAppAsyncThunk(
  GET_ALERT_TYPE,
  GetAlertService,
  {}
);

export const getSentTriggeredHistoryAlert = createAppAsyncThunk(
  GET_TRIGGER_ALERT_TYPE,
  GetSentHistoryAlert,
  {}
);

export const addAlert = createAppAsyncThunk(ADD_ALERT_TYPE, AddAlertService, {
  successMessage: { show: true },
  errorMessage: { show: true },
});

export const updateAlert = createAppAsyncThunk(
  UPDATE_ALERT_TYPE,
  UpdateAlertService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const deleteAlert = createAppAsyncThunk(
  DELETE_ALERT_TYPE,
  DeleteAlertService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const getAuditLogsAlert = createAppAsyncThunk(
  GET_AUDIT_LOG_ALERT_TYPE,
  GetAuditLogAlertService
);

// Alert module action end here

// Aircraft Family Start here

export const getAircraftFamily = createAppAsyncThunk(
  GET_AIRCRAFT_FAM_TYPE,
  GetAircraftFamilyService,
  {}
);

export const addAircraftFamily = createAppAsyncThunk(
  ADD_AIRCRAFT_FAM_TYPE,
  AddAircraftFamilyService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);
// ADD
export const addAircraftMasterData = createAppAsyncThunk(
  ADD_AIRCRAFT_MASTER_TYPE,
  AddAircraftMasterDataService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);
export const updateAircraftFamily = createAppAsyncThunk(
  UPDATE_AIRCRAFT_FAM_TYPE,
  UpdateAircraftFamilyService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const deleteAircraftFamily = createAppAsyncThunk(
  DELETE_AIRCRAFT_FAM_TYPE,
  DeleteAircraftFamilyService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);
// ENGINE FAMILY
export const getEngineFamily = createAppAsyncThunk(
  GET_ENGINE_FAMILY_TYPE,
  GetEngineFamilyService,
  {}
);

// Add Engine Family

export const addEngineFamily = createAppAsyncThunk(
  CREATE_ENGINE_FAMILY_TYPE,
  AddEngineFamilyService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: { show: true },
  }
);

// Update Engine Family
export const updateEngineFamily = createAppAsyncThunk(
  UPDATE_ENGINE_FAMILY_TYPE,
  UpdateEngineFamily,
  {
    successMessage: {
      show: true,
    },
    errorMessage: { show: true },
  }
);

//Delete Engine Family
export const deleteEngineFamily = createAppAsyncThunk(
  DELETE_ENGINE_FAMILY_TYPE,
  DeleteEngineFamily,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

// Aircraft Types:

export const getAircraftTypes = createAppAsyncThunk(
  GET_AIRCRAFT_TYPES,
  GetAircraftTypesService,
  {}
);

// Create

export const addAircraftType = createAppAsyncThunk(
  CREATE_AIRCRAFT_TYPES,
  AddAircraftTypeService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: { show: true },
  }
);

// Update

export const updateAircraftTypes = createAppAsyncThunk(
  UPDATE_AIRCRAFT_TYPES,
  UpdateAircraftTypeService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: { show: true },
  }
);

// Engine Types

export const getEngineTypes = createAppAsyncThunk(
  GET_ENGINE_TYPE,
  GetEngineTypesService,
  {}
);

// Create

export const addEngineType = createAppAsyncThunk(
  ADD_ENGINE_TYPE,
  AddEngineTypeService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: { show: true },
  }
);

// Update

export const updateEngineTypes = createAppAsyncThunk(
  UPDATE_ENGINE_TYPE,
  UpdateEngineTypeService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: { show: true },
  }
);

//Delete

export const deleteEngineTypes = createAppAsyncThunk(
  DELETE_ENGINE_TYPE,
  DeleteEngineTypeService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

// Delete

export const deleteAircraftTypes = createAppAsyncThunk(
  DELETE_AIRCRAFT_TYPES,
  DeleteAircraftTypeService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: { show: true },
  }
);

// Engine Family & Service

export const addEngineAndTypes = createAppAsyncThunk(
  MULTI_EGINE_FAMILY_TYPE,
  AddEngineFamilyAndTypesService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: { show: true },
  }
);

export const updateEngineAndTypes = createAppAsyncThunk(
  UPDATE_MULTI_ENGINE_FAMILY_TYPE,
  UpdateEngineFamilyAndTypesService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: { show: true },
  }
);

export const addEngineMasterData = createAppAsyncThunk(
  ADD_ENGINE_MASTER_TYPE,
  AddeEngineMasterDataService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const getAircraftMaster = createAppAsyncThunk(
  GET_AIRCRAFT_MASTER_TYPE,
  GetAircraftMasterDataService,
  {}
);

export const deleteAircraftMasterData = createAppAsyncThunk(
  DELETE_AIRCRAFT_MASTER_TYPE,
  DeleteMasterDataRecord,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const getEngineMaster = createAppAsyncThunk(
  GET_ENGINE_MASTER_TYPE,
  GetEngineMasterService,
  {}
);

export const deleteEngineMasterData = createAppAsyncThunk(
  DELETE_ENGINE_MASTER_DATA_TYPE,
  DeleteEngineMasterDataRecordService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const getAppLookup = createAppAsyncThunk(
  GET_APP_LOOKUP_TYPE,
  GetAppLookupService,
  {}
);

export const getIlsList = createAppAsyncThunk(GET_ILS_TYPE, GetIlsService, {});

export const addIlsMasterData = createAppAsyncThunk(
  ADD_ILS_TYPE,
  AddIlsMasterService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const deleteIls = createAppAsyncThunk(
  DELETE_ILS_TYPE,
  deleteIlsService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);
