import axiosConfig from "../../../config/axiosConfig";
import { ANALYTICS_URL } from "../../../config/config";
import { createQueryParams, getStringFromArray } from "../../../utils/helper";

export const GetGraphsListService = (params) => {
  try {
    const { user, module, uuid } = params || {};

    const paramsList = {
      user,
      module,
      uuid,
    };

    const queryString = createQueryParams(paramsList);
    const url = `${ANALYTICS_URL}/module/graphs${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const GetAnalyticsFiltersService = () => {
  try {
    return axiosConfig.get(`${ANALYTICS_URL}/filters`);
  } catch (error) {
    throw error;
  }
};

export const GetAnalyticsGraphsService = (params) => {
  try {
    const { uuid, ...rest } = params || {};
    const paramsList = Object.fromEntries(
      Object.entries(rest).map(([key, value]) => {
        if (
          Array.isArray(value) &&
          value.every((item) => item.label && item.value)
        ) {
          return [key, getStringFromArray(value)];
        } else if (value && typeof value === "object" && "value" in value) {
          return [key, value.value];
        } else {
          return [key, value];
        }
      })
    );
    const queryString = createQueryParams(paramsList);

    const url = `${ANALYTICS_URL}/graphDetails/${uuid}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const GetPartNumbersService = (params) => {
  try {
    const { tier } = params || {};

    const paramsList = {
      tier: tier?.value,
    };

    const queryString = createQueryParams(paramsList);
    const url = `${ANALYTICS_URL}/filters/partnumber${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};
