import moment from "moment";
import { accessor } from "../constant/constant";
import { matchSortKey } from "../data/data";

// check for active menu
export const isActive = (item, location) =>
  location.pathname.startsWith(item.path);

// check wheather in any profile page

/**
 * Creates FormData with proper handling of arrays and nested objects
 * @param {Object} options - The options object
 * @param {Object} options.data - The data object to convert to FormData
 * @param {File[]} options.files - Array of files to append
 * @param {string} options.fileKey - Key to use for file uploads (default: "files")
 * @param {string[]} options.arrayFields - Array of field names that should remain as arrays
 * @returns {FormData} The formatted FormData object
 */
export const createFormData = ({
  data,
  files,
  fileKey = "files",
  arrayFields = [],
}) => {
  const formData = new FormData();

  const appendFormData = (key, value) => {
    // Skip undefined values
    if (value === undefined) return;

    // Handle null values
    if (value === null) {
      formData.append(key, "");
      return;
    }

    // Handle Arrays
    if (Array.isArray(value)) {
      // If field is specified in arrayFields, append with brackets notation
      if (arrayFields.includes(key)) {
        value.forEach((item, index) => {
          if (typeof item === "object" && !Array.isArray(item)) {
            Object.entries(item).forEach(([itemKey, itemValue]) => {
              formData.append(`${key}[${index}][${itemKey}]`, itemValue);
            });
          } else {
            formData.append(`${key}[]`, item);
          }
        });
      } else {
        // Default array handling (converts to JSON string)
        formData.append(key, JSON.stringify(value));
      }
      return;
    }

    // Handle Objects
    if (value && typeof value === "object" && !(value instanceof File)) {
      formData.append(key, JSON.stringify(value));
      return;
    }

    // Handle primitive values and Files
    formData.append(key, value);
  };

  // Process all data fields
  Object.entries(data).forEach(([key, value]) => {
    appendFormData(key, value);
  });

  // Handle file uploads
  if (files?.length) {
    files.forEach((file) => {
      formData.append(fileKey, file);
    });
  }

  return formData;
};

export const isProfilePage = (path) => {
  const profilePagePath = [
    "/settings/profile",
    "/settings/profile/me",
    "/settings/change-password",
  ];
  return (
    profilePagePath.includes(path) || path.startsWith("/settings/profile/")
  );
};

// for only textfields
export const getChangedFields = ({ formData, dirtyFields }) => {
  const changedFields = {};
  Object.keys(dirtyFields).forEach((field) => {
    changedFields[field] = formData[field];
  });
  return changedFields;
};

export const setFormErrors = (errors, setError) => {
  if (errors && Object.keys(errors).length > 0) {
    Object.entries(errors).forEach(([field, messages]) => {
      if (Array.isArray(messages) && messages.length > 0) {
        setError(field, {
          type: "manual",
          message: messages[0], // Use the first error message
        });
      }
    });
  }
};

export const isError = (errors) => Object.keys(errors).length > 0;

export const compareArrays = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false; // Check if lengths are the same
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false; // Compare each element
  }
  return true;
};

export const createQueryParams = (paramsList) => {
  const searchParams = new URLSearchParams();

  Object.entries(paramsList).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== "") {
      // searchParams.append(key, value);
      searchParams.append(
        key,
        typeof value === "object" ? JSON.stringify(value) : value
      );
    }
  });

  return searchParams.toString();
};

// sorting params

export const getSortValue = (id, direction, columnData) => {
  const column = columnData.find((item) => item?.id === id);
  if (!column) return "";

  const usersortKeys = [
    "created_by",
    "updated_by",
    "roles",
    "created_by_name",
    "aircraft_familiy",
    "engine_familiy",
    "msn_esn_number",
    "typical_pn",
  ];
  const apiField = usersortKeys.includes(column[accessor])
    ? matchSortKey[column[accessor]]
    : column[accessor];
  return direction === "desc" ? `-${apiField}` : apiField;
};

export const dateFormat = (date, withTime = false) => {
  const formattedDate = date
    ? moment(date).isValid()
      ? withTime
        ? moment(date).format("DD MMM YYYY HH:mm:ss")
        : moment(date).format("DD MMM YYYY")
      : "-"
    : "-";
  return formattedDate;
};
// Remove special characters With Capitalize
export const capitalizeAndFormat = (text) => {
  if (typeof text !== "string") {
    return ""; // Return an empty string or handle it as needed
  }

  const words = text.split("\n").filter((word) => word.trim() !== "");

  const formattedText = words
    .map((word) =>
      word
        .replace(/_/g, " ")
        .trim()
        .split(" ")
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
        .join(" ")
    )
    .join("\n");

  return formattedText;
};

export const getCurrency = (num) => {
  if (num === 0 || (num && !isNaN(num) && isFinite(num))) {
    return Number(num)
      .toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
      .replace("$", "$ ");
  } else {
    return "$0";
  }
};

export const getNumberFormat = (num) => {
  return num === 0 || (num && !isNaN(num) && isFinite(num))
    ? Number(num).toLocaleString("en-US")
    : "-";
};

export const getLowerCase = (email) => {
  return String(email)?.toLocaleLowerCase();
};

export const getStringFromArray = (array) => {
  // Ensure we are working with a valid array
  if (Array.isArray(array) && array.length > 0) {
    return array.map((item) => item?.value).join(",");
  }
  return ""; // Return empty string if it's not a valid array
};

export const getSortedVisibleColumns = (columnData, visibleColumns) => {
  // Create a map of backend columns for easier lookup
  const backEndMap = Array.isArray(visibleColumns)
    ? visibleColumns.reduce((acc, col) => {
        acc[col.key_name] = col;
        return acc;
      }, {})
    : {};

  // Filter and sort columns based on backend visibility and order
  return columnData
    .filter((col) => {
      const backendCol = backEndMap[col.id];
      const isActive = backendCol && backendCol.active;
      const isVisible = col.isVisible !== false;

      // Always show 'action' and 'select' columns
      if (col.id === "action" || col.id === "select") return true;

      // Show columns that are active and visible
      return isActive && isVisible;
    })
    .sort((a, b) => {
      // If a is 'select', it goes first
      if (a.id === "select") return -1;
      if (b.id === "select") return 1;

      // If a is 'action', it goes before others unless b is also 'action'
      if (a.id === "action") return 1;
      if (b.id === "action") return -1;

      // Check if both columns exist in backEndMap
      const orderA = backEndMap[a.id] ? backEndMap[a.id]?.order : Infinity;
      const orderB = backEndMap[b.id] ? backEndMap[b.id]?.order : Infinity;

      // Columns not in backEndMap will come last
      if (orderA === Infinity && orderB === Infinity) return 0; // Both are not present, maintain original order
      if (orderA === Infinity) return 1; // a is not present, push to end
      if (orderB === Infinity) return -1; // b is not present, push to end

      return orderA - orderB; // Normal sorting by order value
    });
};

export const getFormData = ({ data, files, fileKey = "files" }) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value === undefined) return;

    if (value && typeof value === "object") {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  });
  if (files && files?.length) {
    files.forEach((file) => {
      formData.append(fileKey, file);
    });
  }

  return formData;
};

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const formatTimeTo12Hour = (time24) => {
  if (!time24) return "-";
  try {
    const [hours, minutes] = time24.split(":");
    const hour = parseInt(hours);
    const ampm = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minutes} ${ampm}`;
  } catch (error) {
    return time24;
  }
};

// Helper function to validate and format dates
export const validateDates = {
  disablePastDates: (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  },
  disableFutureDates: (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date > today;
  },
};

export const generateFieldsFromInputData = ({
  data,
  nameKey = "label",
  valueKey = "value",
}) => {
  return data?.map((item) => ({
    label: item[nameKey] || "",
    key: item[nameKey] || "",
    fieldType: { type: "input" },
    alternateMatches: [`${item[nameKey]}`],
    example: item[valueKey] || 4300,
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: `${item[nameKey]} is required`,
    //     level: "error",
    //   },
    // ],
  }));
};

export const findIndividualCashflowParam = (data = [], sectionKey) => {
  if (!Array.isArray(data)) {
    return null;
  }
  for (let item of data) {
    if (item.hasOwnProperty(sectionKey)) {
      return item[sectionKey];
    }
  }
  return null;
};

export const getFileNameFromPath = (path) => {
  return path?.split("/")[path?.split("/")?.length - 1];
};

export const convetDateToMonthString = (dateString) => {
  const date = new Date(dateString);
  const headerTitle =
    date.toLocaleString("default", { month: "short" }) +
    " " +
    date.getFullYear();
  return headerTitle;
};

export const transformFilterOptions = (filterDropdowns) => {
  const transformedOptions = {};

  Object.entries(filterDropdowns)?.forEach(([key, values]) => {
    transformedOptions[key] = values?.length
      ? values?.map((value) => ({
          label: value,
          value: value,
        }))
      : [];
  });

  return transformedOptions;
};

export const toPascalCase = (str) => {
  return str
    ?.split("_")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    ?.join(" ");
};

export const convertGraphRes = (graphsList) => {
  const graphs = Object.entries(graphsList).map(([title, graphsData]) => ({
    title,
    graphsData,
  }));
  return graphs;
};

export const normalizeString = (str) => str.replace(/\s+/g, "").toLowerCase();
