import moment from "moment";
import axiosConfig from "../../../config/axiosConfig";
import {
  GET_IN_REPAIR_INVENTORIES_URL,
  GET_INVENTORIES_COUNTRY_NAME_TOTAL_QTY_GRAPH_URL,
  GET_INVENTORIES_PART_TYPE_TOTAL_QTY_GARPH_URL,
  GET_INVENTORY_FILTER_URL,
  GET_INVENTORY_PART_CLASS_TOTAL_QTY_GRAPH_URL,
  GET_INVENTORY_STOCK_SUMMARY_DETAILS_URL,
  GET_INVENTORY_TEARDOWN_LIST_URL,
  GET_INVENTORY_TIER_GRAPH_COUNT_GRAPH_URL,
  GET_INVENTORY_URL,
  SLOW_MOVEING_INVENTORIES_URL,
  UNDER_STOCK_INVENTORIES_URL,
} from "../../../config/config";
import { createQueryParams } from "../../../utils/helper";
import { getFmvValue } from "../../../data/data";

export const GetInventoriesService = (params) => {
  const {
    page,
    per_page,
    qty_available,
    tier,
    from_date,
    to_date,
    status,
    ata_chapter,
    serial_number,
    pn_type,
    pn,
    order_by,
    description,
    stm_unit_cost,
    rec_date,
    stock_visible,
    dynamic_cat,
    full_dynamic_cat,
    application_code,
    fair_market_value,
    fmv,
    fmv_operator,
    fmv_value,
    fmv_start,
    fmv_end,
  } = params;

  const paramsList = {
    page,
    per_page,
    from_date: from_date,
    to_date: to_date,
    tier: tier,
    qty_available: qty_available,
    status: status,
    ata_chapter,
    serial_number,
    pn_type: pn_type,
    pn,
    order_by,
    description,
    stm_unit_cost,
    rec_date,
    stock_visible,
    dynamic_cat,
    full_dynamic_cat,
    application_code,
    fair_market_value,
    fmv,
    dynamic_filters: fmv_operator
      ? {
          suggested_fmv_with_vq: fmv_operator
            ? {
                operator: fmv_operator,
                value: getFmvValue({
                  fmv_operator,
                  fmv_value,
                  fmv_start,
                  fmv_end,
                }),
              }
            : undefined,
        }
      : undefined,
  };
  const queryString = createQueryParams(paramsList);
  const url = `${GET_INVENTORY_URL}${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};

export const GetInventoriesFilterService = (params) => {
  const { filed } = params;
  return axiosConfig.get(`${GET_INVENTORY_FILTER_URL}?filter_key=${filed}`);
};

export const GetInRepairInventoriesService = (params) => {
  const {
    page,
    per_page,
    manufacturer,
    qty_available,
    tier,
    from_date,
    to_date,
    status,
    ata_chapter,
    serial_number,
    pn_type,
    order_by,
    pn,
    application_code,
    fair_market_value,
    stm_unit_cost,
    msn_esn,
    repair_type,
    repair_vendors,
    condition_code,
    description,
    rec_date,
    stock_visible,
    full_dynamic_cat,
    dynamic_cat,
    fmv_operator,
    fmv_value,
    fmv_start,
    fmv_end,
  } = params;

  // Prepare the parameters for the query string (InRepair version)
  const paramsList = {
    page,
    per_page,
    from_date: from_date,
    to_date: to_date,
    tier: tier,
    qty_available: qty_available,
    pnm_auto_key__manufacturer: manufacturer,
    status: status,
    pn_type: pn_type,
    ata_chapter,
    serial_number,
    order_by,
    pn,
    application_code,
    fair_market_value,
    stm_unit_cost,
    msn_esn,
    repair_type,
    repair_vendors,
    condition_code,
    description,
    rec_date,
    stock_visible,
    full_dynamic_cat,
    dynamic_cat,
    dynamic_filters: fmv_operator
      ? {
          suggested_fmv_with_vq: fmv_operator
            ? {
                operator: fmv_operator,
                value: getFmvValue({
                  fmv_operator,
                  fmv_value,
                  fmv_start,
                  fmv_end,
                }),
              }
            : undefined,
        }
      : undefined,
  };

  // Create the query string for the 'InRepair' inventories
  const queryString = createQueryParams(paramsList);
  const url = `${GET_IN_REPAIR_INVENTORIES_URL}${queryString ? `?${queryString}` : ""}`;

  return axiosConfig.get(url);
};

export const GetUnderStockInventoriesService = (params) => {
  const {
    page,
    per_page,
    manufacturer,
    qty_available,
    tier,
    status,
    ata_chapter,
    serial_number,
    pn_type,
    order_by,
    under_stock_threshold,
    application_code,
    part_number,
    fair_market_value,
    qty_reserved,
    qty_sold,
    qty_oh,
    rec_date,
    pn,
    region,
    description,
    full_dynamic_cat,
    dynamic_cat,
    fmv_operator,
    fmv_value,
    fmv_start,
    fmv_end,
  } = params;

  // Prepare the parameters for the query string (InRepair version)
  const paramsList = {
    page,
    per_page,
    // from_date: from_date
    //   ? from_date
    //   : moment().subtract(18, "years").format("YYYY-MM-DD"),
    // to_date: to_date ? to_date : moment().format("YYYY-MM-DD"),
    tier: tier,
    serial_number,
    qty_available: qty_available,
    pnm_auto_key__manufacturer: manufacturer,
    status: status,
    pn_type: pn_type,
    ata_chapter,
    order_by,
    under_stock_threshold,
    application_code,
    part_number,
    fair_market_value,
    qty_reserved,
    qty_sold,
    qty_oh,
    rec_date,
    pn,
    region,
    description,
    full_dynamic_cat,
    dynamic_cat,
    dynamic_filters: fmv_operator
      ? {
          suggested_fmv_with_vq: fmv_operator
            ? {
                operator: fmv_operator,
                value: getFmvValue({
                  fmv_operator,
                  fmv_value,
                  fmv_start,
                  fmv_end,
                }),
              }
            : undefined,
        }
      : undefined,
  };

  // Create the query string for the 'InRepair' inventories
  const queryString = createQueryParams(paramsList);
  const url = `${UNDER_STOCK_INVENTORIES_URL}${queryString ? `?${queryString}` : ""}`;

  return axiosConfig.get(url);
};

export const GetSlowMoveingInventoriesService = (params) => {
  const {
    page,
    per_page,
    manufacturer,
    qty_available,
    tier,
    from_date,
    to_date,
    status,
    ata_chapter,
    serial_number,
    pn_type,
    order_by,
    under_stock_threshold,
    application_code,
    part_number,
    fair_market_value,
    stm_unit_cost,
    qty_reserved,
    qty_sold,
    qty_oh,
    rec_date,
    stock_visible,
    description,
    pn,
    full_dynamic_cat,
    dynamic_cat,
    fmv_operator,
    fmv_value,
    fmv_start,
    fmv_end,
  } = params;

  // Prepare the parameters for the query string (InRepair version)
  const paramsList = {
    page,
    per_page,
    manufacturer,
    qty_available,
    tier,
    from_date: from_date,
    to_date: to_date,
    status,
    ata_chapter,
    serial_number,
    pn_type,
    order_by,
    under_stock_threshold,
    application_code,
    part_number,
    fair_market_value,
    qty_reserved,
    qty_sold,
    qty_oh,
    rec_date,
    stm_unit_cost,
    stock_visible,
    description,
    pn,
    full_dynamic_cat,
    dynamic_cat,
    dynamic_filters: fmv_operator
      ? {
          suggested_fmv_with_vq: fmv_operator
            ? {
                operator: fmv_operator,
                value: getFmvValue({
                  fmv_operator,
                  fmv_value,
                  fmv_start,
                  fmv_end,
                }),
              }
            : undefined,
        }
      : undefined,
  };

  // Create the query string for the 'InRepair' inventories
  const queryString = createQueryParams(paramsList);
  const url = `${SLOW_MOVEING_INVENTORIES_URL}${queryString ? `?${queryString}` : ""}`;

  return axiosConfig.get(url);
};

export const GetInventoryStockSummaryDetailsService = (params) => {
  const { page, per_page, from_date, to_date, pnm_auto_key, type } = params;
  // filter_key: tier,qty_available,pnm_auto_key__manufacturer
  const paramsList = {
    page,
    per_page,
    from_date: from_date
      ? from_date
      : moment().subtract(18, "years").format("YYYY-MM-DD"),
    to_date: to_date ? to_date : moment().format("YYYY-MM-DD"),
    pnm_auto_key: pnm_auto_key,
    type: type,
  };
  const queryString = createQueryParams(paramsList);
  const url = `${GET_INVENTORY_STOCK_SUMMARY_DETAILS_URL}${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};

export const GetInventoryTeardownService = (params) => {
  const {
    page,
    per_page,
    pnm_auto_key,
    type,
    qty_available,
    manufacturer,
    status,
    pn_type,
    ata_chapter,
    serial_number,
    order_by,
    msn_esn,
    fmv,
    category,
    engine_family,
    engine_type,
    aircraft_family,
    aircraft_type,
    part_number,
    project_asset__msn_esn,
    description,
    tier,
    quantity,
    full_dynamic_cat,
    dynamic_cat,
    application_code,
    fair_market_value,
    fmv_operator,
    fmv_value,
    fmv_start,
    fmv_end,
  } = params;

  const paramsList = {
    page,
    per_page,
    pnm_auto_key: pnm_auto_key,
    type: type,
    qty_available: qty_available,
    pnm_auto_key__manufacturer: manufacturer,
    status: status,
    pn_type: pn_type,
    ata_chapter,
    serial_number,
    order_by,
    msn_esn,
    fmv,
    category,
    engine_type,
    engine_family,
    aircraft_family,
    aircraft_type,
    part_number,
    project_asset__msn_esn,
    description,
    tier,
    quantity,
    full_dynamic_cat,
    dynamic_cat,
    application_code,
    fair_market_value,
    dynamic_filters: fmv_operator
      ? {
          suggested_fmv_with_vq: fmv_operator
            ? {
                operator: fmv_operator,
                value: getFmvValue({
                  fmv_operator,
                  fmv_value,
                  fmv_start,
                  fmv_end,
                }),
              }
            : undefined,
        }
      : undefined,
  };
  const queryString = createQueryParams(paramsList);
  const url = `${GET_INVENTORY_TEARDOWN_LIST_URL}${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};

export const GetInventoryCountryNameTotalQtyGraphService = (data) => {
  return axiosConfig.get(
    GET_INVENTORIES_COUNTRY_NAME_TOTAL_QTY_GRAPH_URL,
    data
  );
};

export const GetInventoryPartClassTotalQtyGraphService = (data) => {
  return axiosConfig.get(GET_INVENTORY_PART_CLASS_TOTAL_QTY_GRAPH_URL, data);
};

export const GetInventoryTierGraphCountGraphService = (data) => {
  return axiosConfig.get(GET_INVENTORY_TIER_GRAPH_COUNT_GRAPH_URL, data);
};

export const GetInventoryPartTypeTotalQtyGraphService = (data) => {
  return axiosConfig.get(GET_INVENTORIES_PART_TYPE_TOTAL_QTY_GARPH_URL, data);
};
