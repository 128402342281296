import moment from "moment";
import axiosConfig from "../../../config/axiosConfig";
import {
  TOTAL_VENDOR_OVERVIEW_URL,
  VENDOR_COMPANY_CLASS_GRAPH_URL,
  VENDOR_DATE_CREATED_GRAPH_URL,
  VENDOR_OFFICE_REGION_GRAPH_URL,
  VENDORS_URL,
  VENDORSLIST_URL,
} from "../../../config/config";
import { createQueryParams, getStringFromArray } from "../../../utils/helper";

export const GetVendorsService = (params) => {
  const {
    page,
    per_page,
    company_class,
    office_country_name,
    office_region,
    created_by,
    company_name,
    company_code,
    email_address,
    from_date,
    to_date,
    is_vendor,
    is_customer,
    is_supplier,
    ordering,
  } = params;

  const paramsList = {
    page,
    per_page,
    company_class: getStringFromArray(company_class),
    office_country_name: getStringFromArray(office_country_name),
    office_region: getStringFromArray(office_region),
    created_by: getStringFromArray(created_by),
    company_name,
    company_code,
    email_address,
    from_date: from_date ? moment(from_date).format("YYYY-MM-DD") : "",
    to_date: to_date ? moment(to_date).format("YYYY-MM-DD") : "",
    is_vendor: is_vendor ? 1 : undefined,
    is_customer: is_customer ? 1 : undefined,
    is_supplier: is_supplier ? 1 : undefined,
    ordering,
  };
  const queryString = createQueryParams(paramsList);

  const url = `${VENDORSLIST_URL}${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};

export const GetVendorCompanyClassGraph = (data) => {
  return axiosConfig.get(VENDOR_COMPANY_CLASS_GRAPH_URL, data);
};

export const getTotalVendorOverviewService = (data) => {
  return axiosConfig.get(TOTAL_VENDOR_OVERVIEW_URL, data);
};

export const GetVendorOfficeRegionGraph = (data) => {
  return axiosConfig.get(VENDOR_OFFICE_REGION_GRAPH_URL, data);
};

export const GetVendorDateCreatedGraph = (data) => {
  return axiosConfig.get(VENDOR_DATE_CREATED_GRAPH_URL, data);
};

// PO
export const GetVendorPODetailsService = (params) => {
  const { page, per_page, id, ordering } = params;

  const paramsList = {
    page,
    per_page,
    ordering,
  };
  const queryString = createQueryParams(paramsList);

  const url = `${VENDORS_URL}/${id}/purchase-orders${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};

// SO
export const GetVendorSODetailsService = (params) => {
  const {
    page,
    per_page,
    id,
    ordering,
    region,
    part_class,
    primary_application,
    vq_status,
    ro_number,
    country,
    tier,
    pn,
  } = params;
  const paramsList = {
    page,
    per_page,
    ordering,
    tier: tier?.value,
    country: country?.value,
    region: region?.value,
    part_class,
    primary_application,
    vq_status,
    ro_number,
    pn,
  };
  const queryString = createQueryParams(paramsList);

  const url = `${VENDORS_URL}/${id}/sales-orders${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};

// Customer Quotes
export const GetVendorCustomerQuoteDetailsService = (params) => {
  const { page, per_page, id, ordering } = params;

  const paramsList = {
    page,
    per_page,
    ordering,
  };
  const queryString = createQueryParams(paramsList);

  const url = `${VENDORS_URL}/${id}/customer-quotes${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};

// Quotes
export const GetVendorQuoteDetailsService = (params) => {
  const {
    page,
    per_page,
    id,
    ordering,
    region,
    part_class,
    primary_application,
    vq_status,
    ro_number,
    country,
    tier,
    pn,
    part_number,
  } = params;

  const paramsList = {
    page,
    per_page,
    ordering,
    tier: tier?.value,
    country: country?.value,
    region: region?.value,
    part_class: part_class?.value,
    primary_application: primary_application?.value,
    vq_status: vq_status?.value,
    ro_number: ro_number?.value,
    pn,
    part_number,
  };
  const queryString = createQueryParams(paramsList);

  const url = `${VENDORS_URL}/${id}/vendor-quotes${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};

// Invoices
export const GetVendorInvoiceDetailsService = (params) => {
  const {
    page,
    per_page,
    id,
    ordering,
    region,
    part_class,
    primary_application,
    vq_status,
    ro_number,
    country,
    tier,
    pn,
  } = params;

  const paramsList = {
    page,
    per_page,
    ordering,
    tier: tier?.value,
    country: country?.value,
    region: region?.value,
    part_class: part_class?.value,
    primary_application: primary_application?.value,
    vq_status: vq_status?.value,
    ro_number: ro_number?.value,
    pn,
  };
  const queryString = createQueryParams(paramsList);

  const url = `${VENDORS_URL}/${id}/vendor-invoices${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};

//repair
export const GetVendorRepairDetailsService = (params) => {
  const {
    page,
    per_page,
    id,
    ordering,
    region,
    part_class,
    primary_application,
    vq_status,
    ro_number,
    country,
    tier,
    pn,
    ro_entry_date_to,
    ro_entry_date_from,
  } = params;

  const paramsList = {
    page,
    per_page,
    ordering,
    tier: tier?.value,
    country: country?.value,
    region: region?.value,
    part_class: part_class?.value,
    primary_application: primary_application?.value,
    vq_status: vq_status?.value,
    ro_number,
    pn,
    ro_entry_date_to,
    ro_entry_date_from,
  };
  const queryString = createQueryParams(paramsList);

  const url = `${VENDORS_URL}/${id}/repair-orders${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};
