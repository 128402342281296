import axiosConfig from "../../../config/axiosConfig";
import { DEMAND_FORECAST_URL } from "../../../config/config";
import { createQueryParams } from "../../../utils/helper";

export const GetPreviousSalseService = (params) => {
  try {
    const { application_code, tier, regions, part_number } = params || {};

    const paramsList = {
      application_code: application_code?.value,
      tier: tier?.value,
      region: regions?.value,
      part_number: part_number?.value,
    };

    const queryString = createQueryParams(paramsList);
    const url = `${DEMAND_FORECAST_URL}/previous_sales${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const GetTopSellAppService = (params) => {
  try {
    const { years } = params || {};
    const paramsList = {
      years: years?.value,
    };

    const queryString = createQueryParams(paramsList);
    const url = `${DEMAND_FORECAST_URL}/top_selling_applications${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const GetForecastService = (params) => {
  try {
    const { application_code, tier, regions, part_number } = params || {};

    const paramsList = {
      application_code: application_code?.value,
      tier: tier?.value,
      region: regions?.value,
      part_number: part_number?.value,
    };

    const queryString = createQueryParams(paramsList);
    const url = `${DEMAND_FORECAST_URL}/application_forcasting${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const GetSalesFmvService = (params) => {
  try {
    const { part_number } = params || {};

    const paramsList = {
      part_number: part_number?.value,
    };

    const queryString = createQueryParams(paramsList);
    const url = `${DEMAND_FORECAST_URL}/sales_price_vs_fmv${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const GetInventoryMakeupService = (params) => {
  try {
    const queryString = createQueryParams({});
    const url = `${DEMAND_FORECAST_URL}/inventory_makeup_by_tier${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};
