import { createSlice } from "@reduxjs/toolkit";
import {
  addRole,
  addTemplate,
  addUser,
  assignAlternateRole,
  changePassword,
  deleteRole,
  deleteTemplates,
  deleteUser,
  getAuditLogsRole,
  getAuditLogsUser,
  getFeatures,
  getRoles,
  getTemplate,
  getUsers,
  updateRole,
  updateTemplate,
  updateUser,
  updateUserProfile,
  addAnalyticsGraph,
  updateAnalyticsGraph,
  deleteAnalyticsGraph,
  getAnalyticsGraphs,
  getAlert,
  addAlert,
  updateAlert,
  deleteAlert,
  getAuditLogsAlert,
  addAircraftMasterData,
  getAircraftMaster,
  deleteAircraftMasterData,
  getSentTriggeredHistoryAlert,
  addEngineMasterData,
  getAircraftFamily,
  addAircraftFamily,
  updateAircraftFamily,
  deleteAircraftFamily,
  getEngineFamily,
  updateEngineFamily,
  deleteEngineFamily,
  addEngineFamily,
  getAircraftTypes,
  getEngineMaster,
  getEngineTypes,
  addEngineType,
  updateEngineTypes,
  deleteEngineTypes,
  deleteEngineMasterData,
  addEngineAndTypes,
  updateEngineAndTypes,
  getAppLookup,
  getIlsList,
  addIlsMasterData,
  deleteIls,
} from "./settingsActions";

export const initialState = {
  isInitialLoad: true,
  rolesInitialLoad: true,
  graphInitialLoad: true,
  alertInitialLoad: true,
  airFamilyInitialLoad: true,
  engineFamilyInitialLoad: true,
  engineTypeInitialLoad: true,
  masterInitailLoad: true,
  ilsInitialLoad: true,
  loading: false,
  addLoading: false,
  formErrors: {},
  features: {},
  users: {},
  roles: {},
  userAuditLogs: {},
  rolesAuditLogs: {},
  template: {},
  analyticsGraphs: {},
  alert: {},
  alertAuditLogs: {},
  aircraftMasterData: {},
  engineMasterData: {},
  sentHistoryAlert: {},
  airFamState: {},
  aircraftType: {},
  engineType: {},
  engineFamily: {},
  appLookupInitialLoad: true,
  appLookupData: {},
  ilsData: {},
};

const settingsSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    setRoleInitalLoad: (state, action) => {
      state.rolesInitialLoad = action.payload;
    },
    setGraphInitialLoad: (state, action) => {
      state.graphInitialLoad = action.payload;
    },
    setAlertInitialLoad: (state, action) => {
      state.alertInitialLoad = action.payload;
    },
    setAirFamilyInitialLoad: (state, action) => {
      state.airFamilyInitialLoad = action.payload;
    },
    setEngineFamilyInialLoad: (state, action) => {
      state.engineFamilyInitialLoad = action.payload;
    },
    setEngineTypeInitialLoad: (state, action) => {
      state.engineTypeInitialLoad = action.payload;
    },
    setMasterInitialLoad: (state, action) => {
      state.masterInitailLoad = action.payload;
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setAppLookpInitialLoad: (state, action) => {
      state.appLookupInitialLoad = action.payload;
    },
    setIlsInitiaLoad: (state, action) => {
      state.ilsInitialLoad = action.payload;
    },
    clearSettingsData: (state) => {
      // state.rolesInitialLoad = true;
      state.loading = false;
      state.addLoading = false;
      state.formErrors = false;
      state.users = {};
      state.roles = {};
      state.features = {};
      state.template = {};
      state.rolesAuditLogs = {};
      state.userAuditLogs = {};
      state.analyticsGraphs = {};
      state.alert = {};
      state.sentHistoryAlert = {};
      state.alertAuditLogs = {};
      state.aircraftMasterData = {};
      state.airFamState = {};
      state.aircraftType = {};
      state.engineType = {};
      state.engineMasterData = {};
      state.airFamState = {};
      state.engineFamily = {};
      state.appLookupData = {};
      state.ilsData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // user list
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.users = {};
      })
      // rest of the cases remain same
      .addCase(getRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.rolesInitialLoad = false;
        state.roles = action.payload;
      })
      .addCase(getRoles.rejected, (state) => {
        state.loading = false;
        state.rolesInitialLoad = false;
        state.roles = {};
      })
      // add new user
      .addCase(addUser.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addUser.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // update user
      .addCase(updateUser.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // delete user
      .addCase(deleteUser.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.addLoading = false;
      })
      // change user password
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // add role
      .addCase(addRole.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addRole.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(addRole.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // update role
      .addCase(updateRole.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateRole.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // delete role
      .addCase(deleteRole.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteRole.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(deleteRole.rejected, (state) => {
        state.addLoading = false;
      })
      // assign alternate role
      .addCase(assignAlternateRole.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(assignAlternateRole.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(assignAlternateRole.rejected, (state) => {
        state.addLoading = false;
      })
      // get features
      .addCase(getFeatures.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFeatures.fulfilled, (state, action) => {
        state.loading = false;
        state.features = action.payload;
      })
      .addCase(getFeatures.rejected, (state) => {
        state.loading = false;
      })

      // update user profile
      .addCase(updateUserProfile.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })

      // audit Logs users
      .addCase(getAuditLogsUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuditLogsUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userAuditLogs = action.payload;
      })
      .addCase(getAuditLogsUser.rejected, (state) => {
        state.loading = false;
      })

      // audit Logs Rols
      .addCase(getAuditLogsRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuditLogsRole.fulfilled, (state, action) => {
        state.loading = false;
        state.rolesAuditLogs = action.payload;
      })
      .addCase(getAuditLogsRole.rejected, (state) => {
        state.loading = false;
      })

      // audit Logs Alerts

      .addCase(getAuditLogsAlert.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuditLogsAlert.fulfilled, (state, action) => {
        state.loading = false;
        state.alertAuditLogs = action.payload;
      })
      .addCase(getAuditLogsAlert.rejected, (state) => {
        state.loading = false;
      })

      //get Template

      .addCase(getTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.template = action.payload;
      })
      .addCase(getTemplate.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.template = {};
      })
      // add template
      .addCase(addTemplate.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addTemplate.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(addTemplate.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // update template
      .addCase(updateTemplate.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateTemplate.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(updateTemplate.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })

      // delete tempalte
      .addCase(deleteTemplates.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteTemplates.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(deleteTemplates.rejected, (state, action) => {
        state.addLoading = false;
      })
      // GET GRAPH
      .addCase(getAnalyticsGraphs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAnalyticsGraphs.fulfilled, (state, action) => {
        state.loading = false;
        state.graphInitialLoad = false;
        state.analyticsGraphs = action.payload;
      })
      .addCase(getAnalyticsGraphs.rejected, (state) => {
        state.graphInitialLoad = false;
        state.analyticsGraphs = {};
      })
      // POST GRAPH
      .addCase(addAnalyticsGraph.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addAnalyticsGraph.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(addAnalyticsGraph.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // PUT GRAPH
      .addCase(updateAnalyticsGraph.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateAnalyticsGraph.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(updateAnalyticsGraph.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // DELETE GRAPH
      .addCase(deleteAnalyticsGraph.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteAnalyticsGraph.fulfilled, (state, action) => {
        state.addLoading = false;
        // Remove the deleted graph from the state
        if (state.analyticsGraphs.data) {
          state.analyticsGraphs.data = state.analyticsGraphs.data.filter(
            (graph) => graph.id !== action.meta.arg
          );
        }
      })
      .addCase(deleteAnalyticsGraph.rejected, (state) => {
        state.addLoading = false;
      })
      // Alert GET
      .addCase(getAlert.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAlert.fulfilled, (state, action) => {
        state.loading = false;
        state.alertInitialLoad = false;
        state.alert = action.payload;
      })
      .addCase(getAlert.rejected, (state) => {
        state.loading = false;
        state.alertInitialLoad = false;
        state.alert = {};
      })
      // Alert POST

      .addCase(addAlert.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addAlert.fulfilled, (state, action) => {
        state.addLoading = false;
        state.formErrors = {};
      })

      .addCase(addAlert.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })

      // Alert PATCH

      .addCase(updateAlert.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateAlert.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(updateAlert.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })

      // Alert DELETE
      .addCase(deleteAlert.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteAlert.fulfilled, (state, action) => {
        state.addLoading = false;
      })
      .addCase(deleteAlert.rejected, (state) => {
        state.addLoading = false;
      })
      // Get Sent Alert
      .addCase(getSentTriggeredHistoryAlert.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSentTriggeredHistoryAlert.fulfilled, (state, action) => {
        state.loading = false;
        state.sentHistoryAlert = action.payload;
      })
      .addCase(getSentTriggeredHistoryAlert.rejected, (state) => {
        state.loading = false;
      })

      // Aircraft Family GET:

      .addCase(getAircraftFamily.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAircraftFamily.fulfilled, (state, action) => {
        state.loading = false;
        state.airFamilyInitialLoad = false;
        state.airFamState = action.payload;
      })
      .addCase(getAircraftFamily.rejected, (state) => {
        state.loading = false;
        state.airFamilyInitialLoad = false;
        state.airFamState = {};
      })
      //POST
      .addCase(addAircraftFamily.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addAircraftFamily.fulfilled, (state, action) => {
        state.addLoading = false;
        state.formErrors = {};
      })

      .addCase(addAircraftFamily.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      //Update
      .addCase(updateAircraftFamily.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateAircraftFamily.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(updateAircraftFamily.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors = action.payload?.message || {};
      })
      //Delete
      .addCase(deleteAircraftFamily.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteAircraftFamily.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(deleteAircraftFamily.rejected, (state) => {
        state.addLoading = false;
      })

      // Engine Family

      .addCase(getEngineFamily.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEngineFamily.fulfilled, (state, action) => {
        state.loading = false;
        state.engineFamilyInitialLoad = false;
        state.engineFamily = action.payload;
      })
      .addCase(getEngineFamily.rejected, (state) => {
        state.loading = false;
        state.engineFamilyInitialLoad = false;
        state.engineFamily = {};
      })
      //Create
      .addCase(addEngineFamily.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addEngineFamily.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(addEngineFamily.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // Update
      .addCase(updateEngineFamily.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateEngineFamily.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(updateEngineFamily.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors = action.payload?.message || {};
      })
      //Delete

      .addCase(deleteEngineFamily.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteEngineFamily.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(deleteEngineFamily.rejected, (state) => {
        state.addLoading = false;
      })
      // Aircraft Types

      .addCase(getAircraftTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAircraftTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.aircraftType = action.payload;
      })
      .addCase(getAircraftTypes.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.aircraftType = {};
      })

      // add aircraft master data
      .addCase(addAircraftMasterData.pending, (state) => {
        state.addLoading = true;
        state.loading = true;
      })
      .addCase(addAircraftMasterData.fulfilled, (state) => {
        state.addLoading = false;
        state.loading = false;
      })
      .addCase(addAircraftMasterData.rejected, (state, action) => {
        state.addLoading = false;
        state.loading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })

      //Engine Types

      .addCase(getEngineTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEngineTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.engineTypeInitialLoad = false;
        state.engineType = action.payload;
      })
      .addCase(getEngineTypes.rejected, (state) => {
        state.loading = false;
        state.engineTypeInitialLoad = false;
        state.engineType = {};
      })
      // POST
      .addCase(addEngineType.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addEngineType.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(addEngineType.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // PATCH
      .addCase(updateEngineTypes.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateEngineTypes.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(updateEngineTypes.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors = action.payload?.message || {};
      })

      // DELETE

      .addCase(deleteEngineTypes.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteEngineTypes.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(deleteEngineTypes.rejected, (state) => {
        state.addLoading = false;
      })

      .addCase(addEngineAndTypes.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addEngineAndTypes.fulfilled, (state, action) => {
        state.addLoading = false;
        state.formErrors = {};
      })

      .addCase(addEngineAndTypes.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // PATCH

      .addCase(updateEngineAndTypes.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateEngineAndTypes.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(updateEngineAndTypes.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors = action.payload?.message || {};
      })

      // aircraft masterdata list
      .addCase(getAircraftMaster.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAircraftMaster.fulfilled, (state, action) => {
        state.loading = false;
        state.masterInitailLoad = false;
        state.aircraftMasterData = action.payload;
      })
      .addCase(getAircraftMaster.rejected, (state) => {
        state.loading = false;
        state.masterInitailLoad = false;
        state.aircraftMasterData = {};
      })

      // delete master data record
      .addCase(deleteAircraftMasterData.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteAircraftMasterData.fulfilled, (state, action) => {
        state.addLoading = false;
      })
      .addCase(deleteAircraftMasterData.rejected, (state) => {
        state.addLoading = false;
      })

      // add engine master data
      .addCase(addEngineMasterData.pending, (state) => {
        state.addLoading = true;
        state.loading = true;
      })
      .addCase(addEngineMasterData.fulfilled, (state) => {
        state.addLoading = false;
        state.loading = false;
      })
      .addCase(addEngineMasterData.rejected, (state, action) => {
        state.addLoading = false;
        state.loading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // get engine master
      .addCase(getEngineMaster.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEngineMaster.fulfilled, (state, action) => {
        state.loading = false;
        state.masterInitailLoad = false;
        state.engineMasterData = action.payload;
      })
      .addCase(getEngineMaster.rejected, (state) => {
        state.loading = false;
        state.masterInitailLoad = false;
        state.engineMasterData = {};
      })
      // delete master data record
      .addCase(deleteEngineMasterData.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteEngineMasterData.fulfilled, (state, action) => {
        state.addLoading = false;
      })
      .addCase(deleteEngineMasterData.rejected, (state) => {
        state.addLoading = false;
      })
      // list app lookup
      .addCase(getAppLookup.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAppLookup.fulfilled, (state, action) => {
        state.loading = false;
        state.appLookupInitialLoad = false;
        state.appLookupData = action.payload;
      })
      .addCase(getAppLookup.rejected, (state) => {
        state.loading = false;
        state.appLookupInitialLoad = false;
        state.appLookupData = {};
      })
      // ils list
      .addCase(getIlsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIlsList.fulfilled, (state, action) => {
        state.loading = false;
        state.ilsInitialLoad = false;
        state.ilsData = action.payload;
      })
      .addCase(getIlsList.rejected, (state) => {
        state.loading = false;
        state.ilsInitialLoad = false;
        state.ilsData = {};
      })
      // add ILS master data
      .addCase(addIlsMasterData.pending, (state) => {
        state.addLoading = true;
        state.loading = true;
      })
      .addCase(addIlsMasterData.fulfilled, (state) => {
        state.addLoading = false;
        state.loading = false;
      })
      .addCase(addIlsMasterData.rejected, (state, action) => {
        state.addLoading = false;
        state.loading = false;
      })
      // delete ils record
      .addCase(deleteIls.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteIls.fulfilled, (state, action) => {
        state.addLoading = false;
      })
      .addCase(deleteIls.rejected, (state) => {
        state.addLoading = false;
      });
  },
});

export const {
  setInitialLoad,
  setRoleInitalLoad,
  setGraphInitialLoad,
  setAlertInitialLoad,
  setAirFamilyInitialLoad,
  setEngineFamilyInialLoad,
  setEngineTypeInitialLoad,
  clearSettingsData,
  setMasterInitialLoad,
  setFormErrors,
  setAppLookpInitialLoad,
  setIlsInitiaLoad,
} = settingsSlice.actions;
export default settingsSlice.reducer;
