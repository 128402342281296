// auth
export const LOGIN_TYPE = "auth/userLogn";
export const VALIDATE_LINK_TYPE = "auth/validateLink";
export const VALIDATE_AZURE_LINK_TYPE = "auth/validateAzureLink";
export const SET_PASSSWORD_TYPE = "auth/setPassword";
export const FORGOT_PASSSWORD_TYPE = "auth/forgotPassword";
export const RESENT_PASSWORD_LINK_TYPE = "auth/resendPasswordLink";
export const REFRESH_TOKEN_TYPE = "auth/refreshToken";
export const LOGOUT_TYPE = "auth/userLogout";
export const GET_USER_PROFILE_TYPE = "auth/getUserProfile";
export const GET_ASSET_STAGE_TYPE = "auth/GetAssetStageService";
export const UPDATE_ASSET_STAGE_TYPE = "auth/updateAssetStage";

// users and roles
export const GET_USERS_TYPE = "setting/getUsers";
export const GET_ROLES_TYPE = "setting/getRoles";
export const ADD_USER_TYPE = "setting/addUser";
export const UPDATE_USER_TYPE = "setting/updateUser";
export const DELETE_USER_TYPE = "setting/deleteUser";
export const DELETE_ROLE_TYPE = "setting/deleteRole";
export const ALTERNATE_ROLE_TYPE = "setting/assignAlternateRole";
export const CHANGE_PASSWORD_TYPE = "setting/changePassword";
export const ADD_ROLE_TYPE = "setting/addRole";
export const UPDATE_ROLE_TYPE = "setting/updateRole";
export const GET_FEATURES_TYPE = "setting/getFeatures";
export const UPDATE_PROFILE_TYPE = "setting/updateUserProfile";
export const GET_AUDIT_LOGS_USER_TYPE = "setting/getAuditLogsUser";
export const GET_AUDIT_LOGS_ROLE_TYPE = "setting/getAuditLogsRole";
export const ADD_TEMPLATE_TYPE = "setting/template";
export const UPDATE_TEMPLATE_TYPE = "setting/updateTemplate";
export const GET_TEMPLATE_TYPE = "setting/getTemplate";
export const DELETE_TEMPLATE_TYPE = "setting/deleteProjects";
export const GET_ANALYTICS_GRAPHS_TYPE = "setting/getGraphs";
export const ADD_ANALYTICS_GRAPH_TYPE = "setting/addGraph";
export const UPDATE_ANALYTICS_GRAPH_TYPE = "setting/updateGraph";
export const DELETE_ANALYTICS_GRAPH_TYPE = "setting/deleteGraph";
export const GET_ALERT_TYPE = "setting/getAlert";
export const ADD_ALERT_TYPE = "setting/addAlert";
export const UPDATE_ALERT_TYPE = "setting/updateAlert";
export const DELETE_ALERT_TYPE = "setting/deleteAlert";
export const GET_AUDIT_LOG_ALERT_TYPE = "setting/getAlerts";
export const GET_TRIGGER_ALERT_TYPE = "setting/triggered-alerts";
export const ADD_AIRCRAFT_MASTER_TYPE = "setting/addAircraftMasterData";
export const ADD_ENGINE_MASTER_TYPE = "setting/addEngineMasterData";
export const GET_AIRCRAFT_MASTER_TYPE = "setting/getAircraftMaster";
export const DELETE_AIRCRAFT_MASTER_TYPE = "setting/deleteAircraftMasterData";
export const GET_AIRCRAFT_FAM_TYPE = "setting/aircraft-families";
export const ADD_AIRCRAFT_FAM_TYPE = "setting/create_aircraft_family";
export const UPDATE_AIRCRAFT_FAM_TYPE = "settings/updateAircraftFamily";
export const DELETE_AIRCRAFT_FAM_TYPE = "settings/deleteAircraftFamily";
export const GET_ENGINE_FAMILY_TYPE = "settings/engine-families";
export const DELETE_ENGINE_FAMILY_TYPE = "settings/delete_engine_family";
export const CREATE_ENGINE_FAMILY_TYPE = "settings/create_engine_family";
export const GET_AIRCRAFT_TYPES = "settings/aircraft-types";
export const CREATE_AIRCRAFT_TYPES = "settings/create_aircraft_type";
export const UPDATE_AIRCRAFT_TYPES = "settings/update_aircraft_type";
export const DELETE_AIRCRAFT_TYPES = "settings/delete_aircraft_type";
export const DELETE_ENGINE_MASTER_DATA_TYPE = "settings/deleteEngineMasterData";
export const UPDATE_ENGINE_FAMILY_TYPE = "settings/update_engine_family";
export const GET_ENGINE_MASTER_TYPE = "settings/getEngineMaster";
export const GET_ENGINE_TYPE = "settings/engine-types";
export const ADD_ENGINE_TYPE = "settings/create_engine_type";
export const UPDATE_ENGINE_TYPE = "settings/update_engine_type";
export const DELETE_ENGINE_TYPE = "settings/delete_engine_type";
export const GET_APP_LOOKUP_TYPE = "settings/getAppLookup";
export const GET_ILS_TYPE = "settings/getIlsList";
export const ADD_ILS_TYPE = "settings/addIlsMasterData";
export const DELETE_ILS_TYPE = "settings/deleteIls";

// Multi Engine Family Dummy

export const MULTI_EGINE_FAMILY_TYPE = "settings/create-engine-dummy";
export const UPDATE_MULTI_ENGINE_FAMILY_TYPE = "settings/update-engine-dummy";
export const CUSTOMER_TYPE = "settings/get-customer";
export const VENDER_TYPE = "settings/get-vendor";
export const DELET_PART_ROW_TYPE = "fliptrade/delete-part";

// Multi FlipTrading Dummy

export const GET_FLIPTRADE_TYPE = "settings/get-fliptrade";
export const ADD_FLIPTRADING_TYPE = "settings/create-fliptrade";
export const UPDATE_FLIPTRADING_TYPE = "settings/update-fliptrade";

// ColumnPreferences
export const ADD_COLUMN_PREFERENCES_TYPE = "common/addColumnPreferences";
export const GET_COLUMN_PREFERENCES_TYPE = "common/getColumnPreferences";
export const GET__GENERAL_GENCOLUMN_PREFERENCES_TYPE =
  "common/getGeneralColumnPreferences";
export const UPDATE_COLUMN_PREFERENCES_TYPE = "common/upDateColumnPreferences";
export const GET_CONSTANTS_TYPE = "common/getContants";
export const GET_AIRCRAFT_FAMILIES_TYPE = "common/getAircraftFamiliy";
export const GET_ENGINE_FAMILIES_TYPE = "common/getEngineFamily";
export const GET_ENGINE_TYPE_TYPE = "common/getEngineFamilyTypes";
export const DELETE_ATTACHMENTS_TYPE = "common/deleteAttachments";
// inventories
export const GET_INVENTORIES_LIST_TYPE = "inventories/getInventories";
export const GET_INVENTORIES_FILTER_TYPE = "inventories/getInventoriesFilter";
export const GET__VENDOR_DETAILS_GENERAL_GENCOLUMN_PREFERENCES_TYPE =
  "inventories/getVendorDetailsGeneralColumnPreferences";
export const ADD_VENDOR_DETAILS_COLUMN_PREFERENCES_TYPE =
  "inventories/addVendorDetailsColumnPreferences";
export const UPDATE_VENDOR_DETAILS_COLUMN_PREFERENCES_TYPE =
  "inventories/upDateVendorDetailsColumnPreferences";
export const GET_IN_REPAIR_INVENTORIES_TYPE =
  "inventories/getInventoriesInRepairInventories";
export const GET_UNDER_STOCK_INVENTORIES_TYPE =
  "inventories/getUnderStockInventoriesList";
export const GET_SLOW_MOVEING_INVENTORIES_TYPE =
  "inventories/getSlowMoveingInventoriesList";
export const GET_STOCK_SUMMARY_INVENTORY_DETAILS_TYPE =
  "inventories/getStockSummaryInventoryDetails";
export const GET_INVENTORIES_TEARDOWN_LIST_TYPE =
  "inventories/getInventoryTeardownInventoryList";
export const GET_INVENTORIES_COUNTRY_NAME_TOTAL_QTY_GRAPH_TYPE =
  "inventory/getInventoryCountryNameTotalQtyGraph";
export const GET_INVENTORY_PART_CLASS_TOTAL_QTY_GRAPH_TYPE =
  "inventory/getInventoryPartClassTotalQtyGraph";
export const GET_INVENTORY_TIER_GRAPH_COUNT_GRAPH_TYPE =
  "inventory/getInventoryTierGraphCountGraph";
export const GET_INVENTORIES_PART_TYPE_TOTAL_QTY_GARPH_TYPE =
  "inventory/getInventoryPartTypeTotalQtyGraph";
//vendors
export const TOTAL_VENDOR_OVERVIEW = "vendor/getTotalVendorOverview";
export const GET_VENDORS_TYPE = "vendors/getVendors";
export const GET_FILTER_DROPDOWN_TYPE = "common/getFilterDropdown";
export const GET_VENDOR_COMPANY_CLASS_GRAPH_TYPE =
  "vendors/getVendorCompanyClassGraph";
export const GET_VENDOR_OFFICE_REGION_GRAPH_TYPE =
  "vendors/getVendorOfficeRegionGraph";
export const GET_VENDOR_DATE_CREATED_GRAPH_TYPE =
  "vendors/getVendorDateCreatedGraph";
export const GET_VENDOR__PO_DETAILS_TYPE = "vendors/getVendorPODetails";
export const GET_VENDOR__SO_DETAILS_TYPE = "vendors/getVendorSODetails";
export const GET_VENDOR__CUSTOMER_QUOTES_DETAILS_TYPE =
  "vendors/getVendorCustomerQuotesDetails";
export const GET_VENDOR__QUOTES_DETAILS_TYPE = "vendors/getVendorQuotesDetails";
export const GET_VENDOR__SERVICES_DETAILS_TYPE =
  "vendors/getVendorInvoicesDetails";
export const GET_VENDOR__REPAIR_DETAILS_TYPE = "vendors/getVendorRepairDetails";
// projects
export const GET_PROJECTS_TYPE = "project/getProjects";
export const ADD_PROJECTS_TYPE = "project/addProjects";
export const UPDATE_PROJECTS_TYPE = "project/updateProjects";
export const DELETE_PROJECTS_TYPE = "project/deleteProjects";
// asset
export const GET_ASSET_TYPE = "project/getAssets";
export const ADD_ASSET_TYPE = "project/addAsset";
export const UPDATE_ASSET_TYPE = "project/updateAsset";
export const DELETE_ASSET_TYPE = "project/deleteAssets";
export const PROJECT_SUMMARY_TYPE = "project/getProjectSummary";
export const UPDATE_REPAIR_PERCENTAGE = "project/updateRepairPercentange";
export const GET_ENGINE_UTILISATION_PARAMS =
  "project/getEngineUtilisationParams";
export const UPDATE_ENGINE_UTILISATION_PARAMS =
  "project/updatetEngineUtilisationParams";
export const GET_LLP_VALUATION_LIST = "project/getLLPValuationList";
export const GET_LLP_VALUATION_PART_DISCRIPTION_OPTION =
  "project/getLLPValuaPardiscriptionOption";
export const CREATE_LLP_CYCLE = "project/creatLlpCycle";
export const DELETE_LLP_CYCLE = "project/deleteLlpCycle";
export const CREAT_ESCALATON_LIST_TYPE = "project/creatEscalation";
export const CREAT_LLP_VALUATION = "project/creatLLPValues";
export const UPDATE_LLP_VALUATION = "project/updateLLPValues";
export const UPDATE_ESCALATON = "project/updateEscalation";
export const DELETE_ESCALATON_TYPE = "project/deleteEscalation";
export const GET_ESCALATON_LIST_TYPE = "project/getEscalation";

//Go for part purchase
export const GET_PIECE_PART_TYPE = "purchase/getPiecePart";
// harvest list
export const UPLOAD_HARVEST_LIST_TYPE = "harvest/uploadHarvestList";
export const GET_HARVEST_LIST_TYPE = "harvest/getHarvestList";
export const GET_HARVEST_LIST_COMPONENTS_TYPE =
  "harvest/getHarvestListComponents";
// inventory check
export const GET_INVENTORY_CHECK_TYPE =
  "inventoryCheck/getHarvestListComponents";
// cash flow
export const INDIVIDUAL_CASHFLOW_PARAM_TYPE =
  "cashflow/getIndividualCashflowParam";
export const ENGINE_INDIVIDUAL_CASHFLOW_PARAM_TYPE =
  "cashflow/getEngineIndividualCashflowParam";
export const GET_CASHFLOW_RESULT_TYPE = "cashflow/getCashFlowResult";
export const GET_ENGINE_CASHFLOW_RESULT_TYPE =
  "cashflow/getEngineCashFlowResult";
export const UPDATE_CASHFLOW_TYPE = "cashflow/updateCashFlow";
export const ENGINE_UPDATE_CASHFLOW_TYPE = "cashflow/updateEngineCashFlow";
export const CASHFLOW_PARAM_CREATE_TYPE = "cashflow/creatCashFlow";
export const GET_INVENTORY_CHECK_SUMMARY_TYPE =
  "inventoryCheck/getAircraftInventoryCheckSummary";
export const UPDATE_REPAIR_PERCENTAGE_TYPE =
  "inventoryCheck/updateRepairPercentange";
export const UPDATE_ASSUMED_CONFIG_TYPE = "inventoryCheck/updateConfigSales";
export const UPDATE_INV_CHECK_RP_TYPE = "inventoryCheck/updateInvCheckRP";
// ebita
export const GET_EBITA_TYPE = "ebita/getEbita";
export const GETRBITA_GRAPH_TYPE = "ebita/getEbitaGraphData";
export const GET_ENGINE_EBITA_TYPE = "ebita/engineGetEbita";

// pricing
export const GET_PRICING_TYPE = "pricing/getPricing";
export const GET_PRICING_SUMMARY_TYPE = "pricing/getPricingSummary";
export const UPDATE_PRICING_TYPE = "pricing/updatePricing";
export const UPDATE_PRICING_RP_TYPE = "pricing/updatePricingRP";

// analytics
export const GET_GRAPHS_LIST_TYPE = "analytics/getGraphsList";
export const ANALYTICS_FILTER_TYPE = "analytics/analyticsFilterDropdowns";
export const ANALYTICS_GRAPHS_TYPE = "analytics/analyticsGraphsJSON";
export const PART_NUMBER_LIST_TYPE = "analytics/getPartNumberList";

// home
export const GET_PINNED_GRAPH_TYPE = "home/getPinnedGraphs";
export const PIN_GRAPH_TYPE = "home/pinGraph";

// demand-forecast
export const PREVOUS_SALES_DF_TYPE = "df/getPreviousSales";
export const TOP_SELL_APP_DF_TYPE = "df/getTopSellApp";
export const FORECAST_DF_TYPE = "df/getForecast";
export const GET_SALES_FMV_TYPE = "df/getSalesFmv";
export const GET_INV_MAKEUP_TYPE = "df/getInventoryMakeup";
