export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
// auth
export const LOGIN_URL = "users/auth/login";
export const VALIDATE_LINK_URL = "users/validate_link";
export const VALIDATE_AZURE_LINK_URL = "users/entra/ssoSuccess";
export const SET_PASSWORD_URL = "users/auth/password/reset";
export const FORGOT_PASSWORD_URL = "users/auth/password/forgot";
export const SEND_PASSWORD_LINK_URL = "users/auth/resend";
export const REFRESH_TOKEN_URL = `${BASE_URL}users/auth/refresh`;
export const LOGOUT_URL = "users/auth/logout";
export const AZURE_LOGIN_URL = "entra/auth/login";
export const AZUERE_LOGOUT_URL = "entra/auth/logout";
// setings
export const USERS_URL = "users/users";
export const ROLES_URL = "users/roles";
export const TEMPLATE_URL = "template";
export const ALTERNATE_ROLE_URL = "users/updateuserrole";
export const CHANGE_PASSWORD_URL = "users/auth/password/change";
export const GET_FEATURES_URL = "users/features";
export const USER_PROFILE_URL = "users/profile";
export const GET_AUDIT_LOG_FOR_USER_URL = "auditlog";
export const GET_AUDIT_LOG_FOR_ROLE_URL = "auditlog";
export const ANALYTICS_GRAPHS_URL = "analytics/graphs/";
export const USER_GRAPHS_URL = "users/graphs";
export const ALERT_URL = "alerts";
export const GET_AUDIT_LOG_FOR_ALERT = "auditlog";
export const GET_TRIGGER_ALERT = "triggered-alerts";
export const GET_AIRCRAFT_FAMILY = "config/families/aircraft-families";
export const CREATE_AIRCRAFT_FAMILY_URL =
  "config/families/create_aircraft_family/";
export const UPDATE_AIRCRAFT_FAMILY_URL =
  "config/families/update_aircraft_family/";
export const ENGINE_FAMILY_URL = "config/families/";
export const TYPE_URL = "config/types";
export const APP_LOOKUP_URL = "application-lookup";
export const CONFIG_EXPORT_URL = "config/export";
// export const AIRCRAFT_TYPES_URL = "config/types";
// export const ENGINE_TYPES_URL = "config/types";

// Dummy for Aircraft Family Types [&&] Engine Family and Type [&&] FlipTrading.

export const MULTI_AIRCRAFT_FAMILY_URL = "/api/aircraft_family_multi/dummy";

// FLIP TRADE

export const FLIPTRADE_URL = "fliptrading/fliptrade";
export const CUSTOMER_VENDER_URL = "config/vendor-dropdown-values";
export const DELETE_PART_ROW_URL = "fliptrading/trade/parts";
export const DELETE_FLIPTRADE_ATTACHMENTS_URL = "fliptrading/trade/attachment";

// inventories
export const GET_INVENTORY_URL = "inventory/items";
export const GET_INVENTORY_FILTER_URL = "inventory/items/inventory_dropdown";
export const GET_GENERAL_COLUMN_PREFERENCES_URL =
  "config/general-column-preferences";
export const GET_INVENTORY_STOCK_SUMMARY_DETAILS_URL =
  "/inventory/items/inventory_stock_summary";
export const GET_INVENTORY_TEARDOWN_LIST_URL = "/inventory/items/teardown";
export const ADD_COLUMN_PREFERENCES_URL = "config/column-preferences/";
export const UPDATE_COLUMN_PREFERENCES_URL = `config/column-preferences`;
export const GET_IN_REPAIR_INVENTORIES_URL =
  "inventory/items/inventory_in_repair";
export const INVENTORY_EXPORT_EXCEL_URL = "inventory/items/download_inventory";
export const VENDOR_EXPORT_EXCEL_URL = "vendors";
export const EXPORT_PIECE_PART_URL = "inventory/piece_part_master/export";
export const PART_PURCHASE_EXPORT_EXCEL_URL =
  "inventory/piece_part_master/export";
export const UNDER_STOCK_INVENTORIES_URL =
  "inventory/items/inventory_under_stock";
export const SLOW_MOVEING_INVENTORIES_URL =
  "inventory/items/slowdown_inventory";
export const GET_INVENTORIES_COUNTRY_NAME_TOTAL_QTY_GRAPH_URL =
  "inventory/graphs/country_name_qty_graph_analytics";
export const GET_INVENTORY_PART_CLASS_TOTAL_QTY_GRAPH_URL =
  "inventory/graphs/part_class_graph_analytics";
export const GET_INVENTORY_TIER_GRAPH_COUNT_GRAPH_URL =
  "inventory/graphs/tier_graph_analytics";
export const GET_INVENTORIES_PART_TYPE_TOTAL_QTY_GARPH_URL =
  "inventory/graphs/tier_graph_analytics";
// vendors
export const VENDORSLIST_URL = "vendors/list";
export const VENDORS_URL = "vendors";
export const TOTAL_VENDOR_OVERVIEW_URL = "vendors/total_vendor_overview";
export const VENDOR_COMPANY_CLASS_GRAPH_URL = "vendors/graphs/company-class";
export const VENDOR_OFFICE_REGION_GRAPH_URL = "vendors/graphs/office-region";
export const VENDOR_DATE_CREATED_GRAPH_URL = "vendors/graphs/date-created";
export const VENDORS_LIST_EXPORT_URL = "vendors/profile/download-excel";

// export excel url
export const EXPORT__AUTH_EXCEL_URL = "users/export";
export const EXPORT__EXCEL_URL = "export-excel";

// common
export const GET_FILTER_DROPDOWN_URL = "config/dropdown-values";
export const GET_CONSTANTS_URL = "config/constants";
export const GET_FAMILIES__URL = "config/families";
export const GET_FAMILIES__TYPES_URL = "config/families/engine-families";

// project
export const PROJECT_URL = "projects";
export const PROJECT_URL_ENGINE_UTILISATION = "projects/engine-optimization";
export const GET_LLP_VALUATION_LIST_URL = "projects/engine-llp-valuation";
export const GET_LLP_VALUATION_PART_DICRIPTION_OPTION_URL =
  "projects/engine-llp-values";
export const CREATE_LLP_CYCLE_URL = "projects/engine-llp-valuation/cycle";
export const GET_ESCALATON_LIST_URL = "projects/engine-escalation";
export const CREATE_LLP_VALUATION_LIST_URL = "projects/engine-llp-valuation";
export const CREATE_ESCALATON_LIST_URL = "projects/engine-escalation";
export const UPDATE_LLP_VALUATION_LIST_URL = "projects/engine-llp-valuation";
export const UPDATE_ESCALATON_LIST_URL = "projects/engine-escalation/update";
export const DELETE_ESCALATON_LIST_URL = "projects/engine-escalation/delete";
//Go for part purchase
export const PIECE_PART_URL = "inventory/piece_part_master";

// assets
export const ASSET_URL = "projects/project-assets";

// harvest list
export const HARVEST_LIST_URL = "projects/harvest-list";

// inventory check
export const INVENTORY_CHECK_URL = "projects/inventory-check";
export const UPDATE_RP_INVENTORY_CHECK_URL =
  "projects/update-inventory-check-repair-percent";
export const UPDATE_LRU_SALES_URL = "projects/lru-sales";
// cash flow

export const CASHFLOW_URL = "projects/cashflow-params";
export const ENGINE_CASHFLOW_URL = "projects/cashflow-params-engine";
export const CASHFLOW_RESULT_URL = "projects/cashflow-result";
export const ENGINE_CASHFLOW_RESULT_URL = "projects/cashflow-engine-result";
export const EXPORT_ENGINE_CASHFLOW_RESULT_EBITA =
  "projects/cashflow-engine-ebita";
export const EXPORT_AIRCRAFT_CASHFLOW_RESULT_EBITA = "projects/cashflow-ebita";

// pricing
export const PRICING_URL = "projects/pricing";
export const UPDATE_BULK_RP_PRICING_URL =
  "projects/update-pricing-repair-percent";
// ebita
export const EBITA_URL = "projects/cashflow-ebita";
export const ENGINE_EBITA_URL = "projects/cashflow-engine-ebita";

// sample export
export const SAMPLE_EXPORT_URL = "config/sample-import";

// project asset export
export const PROJECT_ASSETS_EXPORT_URL = "projects/projects-assets-export";
export const PROJECT_EXPORT_URL = "projects/projects-export";
export const HARVEST_LIST_EXPORT_URL = "projects/harvest-list-export";
export const INV_CHECK_EXPORT_URL = "projects/inventory-check-export";
export const PRICING_EXPORT_URL = "projects/pricing-export";

// master data
export const AIRCRAFT_MASTER_DATA_URL = "aircraft/partsMaster";
export const IMPORT_ENGINE_MASTER_DATA_URL = "config/engine/partsmaster";

// delete attachments

export const DELETE_ATTACHEMENTS_URL = "projects/attachment";

// Fliptrade Export URL

export const FLIPTRADE_EXPORT_URL = "fliptrading/trade/export";
// analytics

export const ANALYTICS_URL = "analytics";
export const DEMAND_FORECAST_URL = "analytics/demandForecast";

export const ILS_URL = "config/ilsrecords";
