import { Box, Button } from "@mui/material";
import React from "react";
import { useState } from "react";

const Expandable = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isLongText = typeof content === "string" && content.length > 12;

  return (
    <Box sx={{ whiteSpace: !isExpanded ? "nowrap" : "" }}>
      {isLongText ? (
        <>
          {isExpanded ? (
            <>
              {content}{" "}
              <Button
                onClick={() => setIsExpanded(false)}
                sx={{
                  textTransform: "none",
                  display: "inline",
                  minWidth: 0,
                  fontSize: "10px",
                  padding: 0,
                }}
              >
                less
              </Button>
            </>
          ) : (
            <>
              {content.substring(0, 12) + "..."}
              <Button
                onClick={() => setIsExpanded(true)}
                sx={{
                  textTransform: "none",
                  display: "inline",
                  minWidth: 0,
                  fontSize: "10px",
                  padding: 0,
                }}
              >
                more
              </Button>
            </>
          )}
        </>
      ) : (
        content
      )}
    </Box>
  );
};

export default Expandable;
